import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";

const PointsDebitReport = () => {
  return (
    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
      Points Debit Report
    </div>
  );
};

function PointDebit() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);

  const loginEmail = sessionStorage.getItem("loginEmail");

  const sortedData = data.sort(
    (a, b) => new Date(b.RejectionDate) - new Date(a.RejectionDate)
  );

  // const columns = [
  //   {
  //     title: "Serial No",
  //     render: (rowData) => rowData.tableData.id + 1,
  //     headerStyle: { fontSize: "15px" },
  //   },
  //   {
  //     title: "From User",
  //     field: "senderId",
  //     headerStyle: { fontSize: "15px" },
  //     render: (rowData) =>
  //       rowData.debitter === "admin@admin.com" ? "Admin" : rowData.senderId,
  //   },
  //   {
  //     title: "To User",
  //     field: "userId",
  //     headerStyle: { fontSize: "15px" },
  //     render: (rowData) =>
  //       rowData.userId === "RL0000" ? "Company" : rowData.receiverId,
  //   },
  //   {
  //     title: "Amount",
  //     field: "point",
  //     filtering: true,
  //     headerStyle: { fontSize: "15px" },
  //   },
  //   {
  //     title: "Debit Date",
  //     headerStyle: { fontSize: "18px" },
  //     render: (rowData) => rowData.dateTime || "Invalid Date",
  //     filtering: true,
  //   },
  // ];
  
  const columns = [
    {
      title: "Serial No",
      render: (rowData) => rowData.tableData.id + 1,
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "From User",
      field: "debitter",
      headerStyle: { fontSize: "15px" },
      render: (rowData) =>
        rowData.debitter === "admin@admin.com" ? "Admin" : rowData.debitter,
    },
    {
      title: "To User",
      field: "userId",  // 👈 yeh fix
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Amount",
      field: "point",
      filtering: true,
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Debit Date",
      headerStyle: { fontSize: "18px" },
      render: (rowData) => rowData.dateTime || "Invalid Date",
      filtering: true,
    },
  ];
  

  const getReports = async () => {
    try {
      let response = await axios.get(`${apiBaseURL}/transactions/debitData`, {
        emailId: loginEmail,
      });
      let data = response.data.data;
      if (data && Array.isArray(data)) {
        setData(data);
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };
  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      <div className="card card-outline card-info">
        <MaterialTable
          title={<PointsDebitReport />}
          data={data}
          columns={columns}
        />
      </div>
    </>
  );
}
export default PointDebit;
