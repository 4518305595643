import React from 'react';

const GameDetailsModal = ({ isOpen, onClose, gameDetails }) => {
  if (!isOpen || !gameDetails || !gameDetails.data || gameDetails.data.length === 0) {
    return <div>No game details available.</div>;
  }

  // Define columns for funtarget game
  let columns = [
    { title: 'Sno.', field: 'id' },
    { title: 'Win Point', field: 'winpoint' },
    { title: 'Win Single No', field: 'Win_singleNo' },
    { title: 'Win X', field: 'winX' },
    { title: 'Played Time', field: 'playedTime' }
  ];

  return (
    <div className={`modal ${isOpen ? 'open' : 'closed'}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Game Details</h2>
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {gameDetails.data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <td key={columnIndex}>{row[column.field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GameDetailsModal;
