// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .header{
margin-top: px;
} */

.navbar-nav .nav-link:hover{
    background-color: rgba(41, 40, 40, 0.726);
}`, "",{"version":3,"sources":["webpack://./src/style/header.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,yCAAyC;AAC7C","sourcesContent":["/* .header{\nmargin-top: px;\n} */\n\n.navbar-nav .nav-link:hover{\n    background-color: rgba(41, 40, 40, 0.726);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
