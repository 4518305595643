import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import DatePicker from "react-datepicker";
import "../../style/Contact.css";
import axios from "axios";
// import { apiBaseURL } from "../../config";
import Swal from 'sweetalert2'
//let apiBaseURL = "https://royalluck.club:5000"
let apiBaseURL = "https://royalluck.club:5000";

const CommissionDetailsList = () =>{
  return(
    <div style = {{fontSize: '18px', fontWeight: 'bold'}}>
      Commission Details List
    </div>
  );
}


function Commission() {
  const [data, setData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [emails, setEmails] = useState([]);
  const [error, setError] = useState('');
  const [error1, setError1] = useState("");

  const [tableData1, setTableData1] = useState([]);


  const handleFromDateChange = (date) => {
    setStartDate(date);
  };
  const handleToDateChange = (date) => {
    setEndDate(date);
  };


  const handleFetchData = async () => {

    try {
      const response1 = await axios.post(
        `${apiBaseURL}/user/SuperData`,
        {
          fromDate: fromDate,
          toDate: toDate,
        }
      );

      if (response1.data.length === 0) {
        setError1("No Record Found");
        setTableData1(!response1.data);
      } else {
        setTableData1(response1.data);
        setError1("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  
  };












  const ViewMasterFunction =(email)=>{
  
    axios.post(`${apiBaseURL}/user/getEmailsByIdmasterid`, { email })
   // axios.post(`${apiBaseURL}/user/getEmailsById`, { email })

    .then(response => {
      const { masteridUsers, users } = response.data.result;
      const tableRows = masteridUsers.map(user => 
        `<tr><td>${user.email}</td><td>${user.commission}</td></tr>`).join('')

        Swal.fire({
          icon: 'success',
          title: 'Master IDs and Commission',
          html: `<div style="background-color: white; color: blue;">
                  <table style="width:100%">
                    <tr>
                      <th>Email</th>
                      <th>Commission</th>
                    </tr>
                    ${tableRows}
                  </table>
                </div>`,
          customClass: {
            title: 'swal-title',
            content: 'swal-text',
          },
          iconHtml: '<i class="fa-solid fa-user-friends"></i>',
        }).then(() => {
          //window.location.reload();
        });


    })


    .catch(error => {
      setEmails([]);
      setError('Error retrieving IDs. Please try again.');
    });
  

     }
 

 const getReports = async () => {
    await axios({
      method: "GET",
      url: `${apiBaseURL}/user/SuperCommission`,
      // data: user,
      // headers: {"Authorization" : `Bearer ${authToken}`}
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        } else {
         
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 
  const columns = [
    { title: "Serial No", headerStyle: { fontSize: '15px' }, render: (rowData) => rowData.tableData.id + 1 },

    { title: "SuperMasterName", field: "name", headerStyle: { fontSize: '15px' } },
    { title: "Commision Percentage", field: "commission", headerStyle: { fontSize: '15px' } },
    {
      title: "Point Played", headerStyle: { fontSize: '15px' },
      field: "played",
      render: (rowData) => (rowData.played !== null ? rowData.played : 0),
    },

    { title: "Total Commission of SuperMaster", field: "total", headerStyle: { fontSize: '15px' }},
    {
      title: "Total Commission of Master",
     field :'totalmaster', headerStyle: { fontSize: '15px' }   }, 
 {
      title: "",
      render: (rowData) => (
        <button
          className="btn btn-danger ml-2"
          onClick={() => ViewMasterFunction(rowData.name)}

        >
          Commission of Master
        </button>
      ),
    },
  ];

  useEffect(() => {
   getReports();
  //  getPlayers();

  }, []);

//   return (
//     <div className="card card-outline card-info">
//       <div className="d-flex row-wrap">
//                <div className="row-left">
//                         <lable>From Date:</lable>
//                         <DatePicker
//                           selected={fromDate}
//                           onChange={(date) => setFromDate(date)}
//                           dateFormat="dd MMM yyyy"
//                           calendarClassName="custom-datrpicker"
//                           selectsFrom
//                           fromDate={fromDate}
//                           toDate={toDate}
//                         />
//                       </div>
//                       <div className="row-mid">
//                         <lable>To Date:</lable>

//                         <DatePicker
//                           selectsTo
//                           selected={toDate}
//                           fromDate={fromDate}
//                           toDate={toDate}
//                           minDate={fromDate}
//                           onChange={(date) => setToDate(date)}
//                           dateFormat="dd MMM yyyy"
//                           calendarClassName="custom-datrpicker"
//                         />
//                       </div>
//                      {/*  <button className="btn btn-primary my-3" */}
//                           {/* // onClick={handleFetchData} */}
//                           {/* // >Find</button> */}

// <div class="btn btn-primary my-3">
//                         <input
//                           type="button"
//                           onClick={handleFetchData}
//                           name="btnShowDetails"
//                           id="btnShowDetails"
//                           value="Show Details"
//                         />
//                       </div>
//              </div>
//              <br/>
//       <MaterialTable
//         title="Commission Detail List"
//         data={tableData1}
//         columns={columns}
//         options={{ actionsColumnIndex: -1 }}
//       />
      
//       {currentRow && (
//         <EditForm
//           row={currentRow}
//           onClose={() => setCurrentRow(null)}
//           apiBaseURL={apiBaseURL}
//          // getPlayers={getPlayers}
//         />
//       )}
//     </div>
//      );
return (
  <div className="card card-outline card-info">
    <br />
    <div style={{ position: "relative", zIndex: 1000, backgroundColor: "#fff", padding: "10px", borderRadius: "5px", color: 'black' }}>
      <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div className="d-flex" style={{ marginRight: "10px" }}>
          <div className="row-left" style={{ marginRight: "10px" }}>
            <label style={{ color: "#000" }}>From Date:</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="dd MMM yyyy"
              placeholderText="Select From Date"
              className = "date-pick1"
              style={{ zIndex: 9999, position: "relative" }}
            />
          </div>
          <div className="row-mid" style={{ marginRight: "10px" }}>
            <label style={{ color: "#000" }}>To Date:</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="dd MMM yyyy"
              placeholderText="Select To Date"
              className = "date-pick2"
              minDate={fromDate}
              style={{ zIndex: 9999, position: "relative" }}
            />
          </div>
        </div>
        <div>
          <button className="btn btn-primary show-details-button" onClick={handleFetchData}>
            Show Details
          </button>
        </div>
      </div>
    </div>
    <br />
    <div style={{ position: "relative" }}>
      <MaterialTable
        title={<CommissionDetailsList />}
        data={tableData1}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
      />
    </div>
    {currentRow && (
      <EditForm
        row={currentRow}
        onClose={() => setCurrentRow(null)}
        apiBaseURL={apiBaseURL}
      />
    )}
  </div>
);

  
}

const handleEdit = async (rowData, apiBaseURL, getPlayers, firstName) => {
  const { id, password } = rowData;
  const newPassword = prompt(
    "Enter new password (leave blank to keep current password)"
  );
  const newFirstName = prompt("Enter new first name", firstName);

  // Check if the user cancelled the prompt for the new first name or new password
  if (newFirstName === null || newPassword === null) {
    return;
  }

  axios
    .put(`${apiBaseURL}/user/updateUser`, {
      id,
      first_name: newFirstName,
      password: newPassword || null,
    })
    .then(function (response) {
      if (response.status === 200) {
        Swal.fire("Success", response.data.message, "success").then(() =>
          getPlayers()
        );
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    })
    .catch(function (error) {
      Swal.fire("Error", "Something went wrong", "error");
    });
};



function EditForm({ row, onClose, apiBaseURL, getPlayers }) {
  const [firstName, setFirstName] = useState(row.first_name);
  const [password, setPassword] = useState(row.password);

  const handleSave = async () => {
    try {
      const updatedData = {
        id: row.id,
        first_name: firstName,
        password: password,
      };
  
      await axios.put(`${apiBaseURL}/user/updateUser`, updatedData);
  
      onClose();
      getPlayers();
      alert("User updated successfully");
    } catch (error) {
      console.error(error);
      alert("Failed to update user");
    }
  };
  

  return (
    <div>
                 
      <div>
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}



export default Commission;
