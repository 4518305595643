import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";

const PointsRejectedReport = () => {
  return (
    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
      Points Rejected Report
    </div>
  );
};

function PointRejected() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);

  const loginEmail = sessionStorage.getItem("loginEmail");

  const sortedData = data.sort(
    (a, b) => new Date(b.RejectionDate) - new Date(a.RejectionDate)
  );

  const columns = [
    {
      title: "Serial No",
      render: (rowData) => rowData.tableData.id + 1,
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "From User",
      field: "senderId",
      headerStyle: { fontSize: "15px" },
      render: (rowData) =>
        rowData.senderId === "admin@admin.com" ? "Admin" : rowData.senderId,
    },
    {
      title: "To User",
      field: "receiverId",
      headerStyle: { fontSize: "15px" },
      render: (rowData) =>
        rowData.receiverId === "RL0000" ? "Company" : rowData.receiverId,
    },
    {
      title: "Amount",
      field: "point",
      filtering: true,
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Rejected Date",
      headerStyle: { fontSize: "18px" },
      render: (rowData) => rowData.dateTime || "Invalid Date",
      filtering: true,
    },
  ];
  //get Agents
  const getReports = async () => {
    try {
      let response = await axios.post(`${apiBaseURL}/transaction/rejectPoint`, {
        emailId: loginEmail,
      });
      let data = response.data.data;
      if (data && Array.isArray(data)) {
        setData(data);
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };
  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      <div className="card card-outline card-info">
        <MaterialTable
          title={<PointsRejectedReport />}
          data={data}
          columns={columns}
        />
      </div>
    </>
  );
}
export default PointRejected;
