import React, { useState, useEffect } from "react";

import "../../style/Contact.css";
import "../points/css1/home2.css";
import axios from "axios";
import Swal from "sweetalert2";

function ManagePoints() {
  let apiBaseURL = "https://royalluck.club:5000";

  const [destriData, setDestriData] = useState([]);
  const [iderr, setIdErr] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [Name, setName] = useState(null);
  const [Point, setPoint] = useState(null);

  const agentLogin = sessionStorage.getItem("loginEmail");

  const [values, setValues] = useState({
    id: "RL",
    point: "",
    pin: "",
  });
  const [transableData, settransableData] = useState([]);
  const [transableData1, settransableData1] = useState([]);

  const [transableIdArr, settransableIdArr] = useState([]);
  const [transableIdArr1, settransableIdArr1] = useState([]);

  const [isChecked, setIsChecked] = useState([]);
  const [isChecked1, setIsChecked1] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectAll1, setSelectAll1] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { id, point, pin } = values;
    const data = {
      receiverId: id,
      senderId: sessionStorage.getItem("loginEmail"),
      senderPassword: pin,
      point: point,
    };

    try {
      let response = await axios.post(
        `${apiBaseURL}/companyId/tranfered`,
        data
      );

      if (response.data.message === "Point transferred successfully") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Point transferred successfully",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });

        window.location.reload();
      } else if (response.data.message == "Incorrect Password") {
        Swal.fire({
          icon: "error",
          title: "Incorrect Password",
          text: "The password you entered is incorrect.",
          confirmButtonColor: "#d33",
          confirmButtonText: "Try Again",
        });

        window.location.reload();
      } else if (response.data.message === "Player Id not found") {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          text: "Player Id not found",
          confirmButtonColor: "#ffa502",
          confirmButtonText: "Try Again",
        });

        window.location.reload();
      } else if (response.data.message === "Please enter a valid amount!") {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          text: "Please enter a valid amount",
          confirmButtonColor: "#ffa502",
          confirmButtonText: "OK",
        });

        window.location.reload();
      } else if (
        response.data.message === "Please Enter receiver id & password !"
      ) {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          text: "Please Enter receiver id & password !",
          confirmButtonColor: "#ffa502",
          confirmButtonText: "OK",
        });

        window.location.reload();
      } else if (
        response.data.message === "Insufficient Balance of sender !!"
      ) {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          text: "Insufficient Balance of sender!!",
          confirmButtonColor: "#ffa502",
          confirmButtonText: "Try Again",
        });

        window.location.reload();
      } else {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonColor: "#ffa502",
          confirmButtonText: "Try Again",
        });

        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Oops!",
        text: "Internet is down or Something wrong. Please try again.",
        confirmButtonColor: "#ffa502",
        confirmButtonText: "Try Again",
      });
    }
  };

  const fetchPlayerPoint = async () => {
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      const response = await axios.post(`${apiBaseURL}/user/getPlayerPoint`, {
        email: emailId,
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.point !== undefined) {
          setPlayerData(responseData.point);
          console.log("playerData updated:", responseData.point);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };

  //get supermaster master user Name
  const getName = async () => {
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      const response = await axios.post(
        `${apiBaseURL}/user/getSuperMasterUserName`,
        {
          email: emailId,
        }
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.Name !== undefined) {
          setName(responseData.Name);
          console.log("playerData updated:", responseData.Name);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };

  // get all point
  const getPoint = async () => {
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      const response = await axios.post(
        `${apiBaseURL}/user/getSuperMasterUserPoint`,
        {
          email: emailId,
        }
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.Point !== undefined) {
          setPoint(responseData.Point);
          console.log("playerData updated:", responseData.Point);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };

  const gettransable = async () => {
    let emailId = sessionStorage.getItem("loginEmail");
    await axios
      .post(`${apiBaseURL}/companyId/transferrable`, {
        emailId: emailId,
      })
      .then(function (response) {
        if (response.data.data) {
          settransableData(response.data.data);
        }
      })
      .catch(function (error) {
        // Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const getreceviable = async () => {
    let emailId = sessionStorage.getItem("loginEmail");
    await axios
      .post(`${apiBaseURL}/companyId/receivable`, {
        emailId: emailId,
      })
      .then(function (response) {
        if (response.data.data) {
          settransableData1(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  const sendtransableId = async () => {
    await axios

      .post(`${apiBaseURL}/companyId/receivable`, { id: isChecked })
      .then(function (response) {
        if (response.data.status === 200) {
          settransableIdArr(response.data.data);
        }
      })
      .catch(function (error) {
        // Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const loginId = sessionStorage.getItem("loginEmail");
  const ClickOnCheckBox = (id) => {
    var updatedList = [...isChecked];

    const selectedItem = transableData.find(
      (item) => item.transactionId === id
    );
    if (!selectedItem) return;

    const itemData = {
      senderId: selectedItem.senderId || null,
      receiverId: selectedItem.receiverId || null,
      point: selectedItem.point || null,
      transactionId: selectedItem.transactionId || null,
    };

    const index = updatedList.findIndex(
      (i) => i.transactionId === itemData.transactionId
    );
    if (index === -1) {
      updatedList.push(itemData);
    } else {
      updatedList = updatedList.filter(
        (i) => i.transactionId !== itemData.transactionId
      );
    }

    setIsChecked(updatedList);
  };

  const sendreceviableId = async () => {
    await axios

      .post(`${apiBaseURL}/companyId/accept`, { transactionData: isChecked1 })
      .then(function (response) {
        settransableIdArr1(response.data.data);
      })
      .catch(function (error) {
        // Swal.fire(`valid player ID enter!`, "error");
      });
  };

  const rejectPoints = async () => {
    try {
      console.log("ischecked1", isChecked1);
      await axios.post(`${apiBaseURL}/companyId/reject`, {
        transactionData: isChecked1,
      });

      console.log("Points rejected successfully!");
    } catch (error) {
      console.error("Error rejecting points:", error);
    }
  };

  const cancelTransfer = async () => {
    try {
      console.log("checked ", isChecked);
      await axios.post(`${apiBaseURL}/companyId/cancel`, {
        transactionData: isChecked,
      });

      console.log("Points rejected successfully!");
    } catch (error) {
      console.error("Error rejecting points:", error);
    }
  };

  const ClickOnCheckBox1 = (id) => {
    var updatedList = [...isChecked1];
    const selectedItem = transableData1.find(
      (item) => item.transactionId === id
    );
    if (!selectedItem) return;

    const itemData = {
      senderId: selectedItem.senderId || null,
      emailId: selectedItem.receiverId || null,
      point: selectedItem.point || null,
      transactionId: selectedItem.transactionId || null,
    };

    const index = updatedList.findIndex(
      (i) => i.transactionId === itemData.transactionId
    );
    if (index === -1) {
      updatedList.push(itemData);
    } else {
      updatedList = updatedList.filter(
        (i) => i.transactionId !== itemData.transactionId
      );
    }
    setIsChecked1(updatedList);
  };

  const handleTransferPoints = async () => {
    try {
      const sender = agentLogin;
      const receive = document.getElementById("toAccNo").value;
      const point = document.getElementById("amount").value;
      const pin = document.getElementById("yourPin").value;

      // const response = await axios.post(`${apiBaseURL}/user/login`, {
      const response = await axios.post(`${apiBaseURL}/user/login`, {
        sender,
        receive,
        point,
        pin,
      });

      if (response.status === 200) {
        console.log("Points transferred successfully");
      } else {
        console.error("Points transfer failed:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleChange = (name) => async (e) => {
    let item = e.target.value;
    setValues({ ...values, [name]: item });
    if (name === "id") {
      try {
        const response = await axios.post(`${apiBaseURL}/user/checkAccount`, {
          id: item,
        });
        if (response.data.exists) {
          setIdErr(false);
        } else {
          setIdErr(true);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    console.log("transable", transableData);

    const updatedList = selectAll
      ? []
      : transableData.map(({ senderId, receiverId, point, transactionId }) => ({
          senderId,
          receiverId,
          point,
          transactionId,
        }));

    setIsChecked(updatedList);
  };

  const handleSelectAll1 = () => {
    setSelectAll1(!selectAll1);

    const updatedList = selectAll
      ? []
      : transableData1.map(
          ({ senderId, receiverId, point, transactionId }) => ({
            senderId,
            emailId: receiverId,
            point,
            transactionId,
          })
        );

    setIsChecked1(updatedList);
  };

  useEffect(() => {
    fetchPlayerPoint();
    getPoint();
    getName();
    getreceviable();
    console.log(sessionStorage.getItem("loginEmail"));
    gettransable();
  }, [transableIdArr]);
  const refresh = () => window.location.reload(true);

  const handleReceiveClick = () => {
    sendreceviableId()
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Receivable ID sent successfully!",
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message || "Something went wrong!",
        });
      });
  };

  const handleRejectClick = () => {
    rejectPoints()
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Points rejected successfully!",
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message || "Something went wrong!",
        });
      });
  };

  const handleCancelClick = () => {
    cancelTransfer()
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Transfer Cancelled successfully!",
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message || "Something went wrong!",
        });
      });
  };

  return (
    <>
      <div className="homepage">
        <div class="row justify-content-between" id="div1"></div>

        <div class="row" id="cl1">
          <div class="col-6 " id="col3">
            <div className="hometop">
              <h5>Manage Points</h5>

              <div className="col-12" id="homecenter">
                <div className="row">
                  <div class="col-6 my-2 " id="center1">
                    <div className="row" id="row1">
                      <div class="col-6 ">
                        <h6 style={{ color: "black" }}>Receivable</h6>
                      </div>
                      <div class="col-6" id="homebtn3">
                        <button
                          className="btn1  btn-info"
                          onClick={refresh}
                          id="home3btn1"
                        >
                          Refresh
                        </button>
                      </div>
                    </div>

                    <div className="row" id="hometable">
                      <div className="example">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" id="hometh">
                                #
                              </th>
                              <th scope="col" id="hometh">
                                From
                              </th>
                              <th scope="col" id="hometh">
                                To
                              </th>
                              <th scope="col" id="hometh">
                                Point
                              </th>
                            </tr>
                          </thead>
                          <tbody id="Homebody">
                            {transableData1.map((item, index) => {
                              return (
                                <tr id="hometr" key={item.transactionId}>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={item.transactionId}
                                        onChange={() =>
                                          ClickOnCheckBox1(item.transactionId)
                                        }
                                        checked={
                                          isChecked1.some(
                                            (i) =>
                                              i.transactionId ===
                                              item.transactionId
                                          ) || selectAll1
                                        }
                                        id="homecheck"
                                      />
                                    </div>
                                  </td>
                                  <td id="hometd" style={{ color: "black" }}>
                                    {item.senderId === "admin@admin.com"
                                      ? "Admin"
                                      : item.senderId}
                                  </td>
                                  <td id="hometd" style={{ color: "black" }}>
                                    {item.receiverId}
                                  </td>
                                  <td id="hometd" style={{ color: "black" }}>
                                    {item.point}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="row"
                      id="row3"
                      style={{ borderTop: "1px solid black" }}
                    >
                      <div class="col-12">
                        <div class="row">
                          <div class="col-4" id="check1">
                            <div class="form-check" id="home1check1">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={selectAll1}
                                onChange={handleSelectAll1}
                                id="flexCheckChecked1"
                              />

                              <label
                                className="form-check-label"
                                for="flexCheckChecked1"
                                style={{ color: "black" }}
                              >
                                Select All
                              </label>
                            </div>
                          </div>
                          <div class="col-4" id="homepoint">
                            <button
                              className="btn1  btn-info"
                              onClick={handleReceiveClick}
                              id="home3btn2"
                            >
                              Receive
                            </button>
                          </div>
                          <div class="col-4 " id="homereject">
                            <button
                              className="btn1 btn-info"
                              onClick={handleRejectClick}
                              id="home3btn3"
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>

                      <br />
                    </div>
                  </div>

                  <div class="col-6 my-2 " id="center2">
                    <div className="row" id="row1">
                      <div class="col-6 ">
                        <h6 style={{ color: "black" }}>Transferable</h6>
                      </div>
                    </div>

                    <div className="row" id="hometable">
                      <div className="example">
                        <table class="table">
                          <thead>
                            <tr className="hometr">
                              <th id="hometh">#</th>
                              <th id="hometh">From</th>
                              <th id="hometh">To</th>
                              <th id="hometh">Point</th>
                            </tr>
                          </thead>

                          <tbody>
                            {}
                            {transableData.map((item, index) => {
                              return (
                                <tr key={item.transactionId}>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        id="homecheck"
                                        type="checkbox"
                                        value={item.transactionId}
                                        onChange={() =>
                                          ClickOnCheckBox(item.transactionId)
                                        }
                                        checked={
                                          isChecked.some(
                                            (i) =>
                                              i.transactionId ===
                                              item.transactionId
                                          ) || selectAll
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td id="hometd" style={{ color: "black" }}>
                                    {item.senderId}
                                  </td>
                                  <td id="hometd" style={{ color: "black" }}>
                                    {item.receiverId}
                                  </td>
                                  <td id="hometd" style={{ color: "black" }}>
                                    {item.point}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="row"
                      id="row3"
                      style={{ borderTop: "1px solid black" }}
                    >
                      <div class="col" id="check2">
                        <div class="form-check" id="home1check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            id="flexCheckChecked2"
                          />

                          <label
                            className="form-check-label"
                            for="flexCheckChecked2"
                            style={{ color: "black" }}
                          >
                            Select All
                          </label>
                        </div>
                      </div>
                      <div class="col " id="homecan">
                        <button
                          id="home3btn5"
                          className="btn1 btn-info"
                          style={{ marginTop: "10px" }}
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" id="last2">
              <h6 className="h6">:: Point Transfer</h6>
              <div className="col-12">
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  className="row needs-validation"
                >
                  <table id="last">
                    <tbody id="home1body">
                      <tr id="hometr1">
                        <td htmlFor="toAccNo" id="t1" className="form-label">
                          To&nbsp;Account&nbsp;No
                        </td>
                        <td>
                          <input
                            type="text"
                            id="toAccNo"
                            name="distributor_id"
                            value={values.id}
                            onChange={handleChange("id")}
                          />
                          {iderr && (
                            <span style={{ color: "red" }} id="homeerr">
                              Please provide a valid ID
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr id="hometr2">
                        <td
                          htmlFor="yourPin"
                          id="t1"
                          style={{ color: "black" }}
                        >
                          Your&nbsp;Pin
                        </td>
                        <td>
                          <input
                            type="password"
                            id="yourPin"
                            name="passcode"
                            value={values.passcode}
                            onChange={handleChange("pin")}
                          />
                        </td>
                      </tr>
                      <tr id="hometr3">
                        <td htmlFor="amount" id="t1" style={{ color: "black" }}>
                          Amount
                        </td>
                        <td id="amount1">
                          <input
                            type="number"
                            id="amount"
                            value={values.point}
                            onChange={handleChange("point")}
                            name="points"
                          />
                        </td>
                      </tr>
                      <div className="col-12" id="btn5">
                        <button
                          className="btn1 btn-info"
                          id="btn6"
                          type="submit"
                          disabled={iderr}
                          onClick={() => {}}
                        >
                          Transfer
                        </button>
                      </div>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ManagePoints;
