

import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import axios from "axios";
import "../../style/Contact.css";

const CommissionDetailsList = () => {
  return (
    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
      Commission Details List
    </div>
  );
};

function Commission() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  let apiBaseURL = "https://royalluck.club:5000";

  // Fetch and map data from the API
  const fetchCommissionData = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getCommissionData`);
      if (response.data.data) {
        const mappedData = response.data.data.map((item) => ({
          id: item.id,
          playerId: item.playerId || "N/A",
          playedPoints: item.playedPoints || 0,
          losePoints: item.losePoints || 0,
          masterCommission: item.masterCommission || "N/A",
          supermasterCommission: item.supermasterCommission || "N/A",
          roundCount: item.roundCount || 0,
         
          updatedAt: item.updatedAt
            ? new Date(item.updatedAt).toLocaleString()
            : "N/A",
          masterEmail: item.masterEmail || "N/A",
          supermasterEmail: item.supermasterEmail || "N/A",
          supermasterShare: item.supermasterShare || "N/A",
          masterShare: item.masterShare || "N/A",
          companyShare: item.company_share || "N/A"
        }));
        setData(mappedData);
        setError("");
      } else {
        setError("No records found.");
        setData([]);
      }
    } catch (err) {
      console.error("Error fetching commission data:", err);
      setError("Failed to fetch data. Please try again later.");
    }
  };

  // Use effect to load data on component mount
  useEffect(() => {
    fetchCommissionData();
  }, []);

  // Define table columns
  const columns = [
    { title: "ID", field: "id", headerStyle: { fontSize: "15px" } },
    { title: "Round Count", field: "roundCount", headerStyle: { fontSize: "15px" } },
    { title: "Username", field: "playerId", headerStyle: { fontSize: "15px" } },
    { title: "Played Points", field: "playedPoints", headerStyle: { fontSize: "15px" } },
    { title: "Lose Points", field: "losePoints", headerStyle: { fontSize: "15px" } },
    { title: "Supermaster Email", field: "supermasterEmail", headerStyle: { fontSize: "15px" } },
    { title: "Supermaster Commission", field: "supermasterCommission", headerStyle: { fontSize: "15px" } },
    { title: "Supermaster Share", field: "supermasterShare", headerStyle: { fontSize: "15px" } },
    { title: "Master Email", field: "masterEmail", headerStyle: { fontSize: "15px" } },
    { title: "Master Commission", field: "masterCommission", headerStyle: { fontSize: "15px" } },
    { title: "Master Share", field: "masterShare", headerStyle: { fontSize: "15px" } },
    {title: "Company Share", field: "companyShare", headerStyle: {fontSize: "15px"}},
    { title: "Updated At", field: "updatedAt", headerStyle: { fontSize: "15px" } },
    
   
  ];

  return (
    <div className="card card-outline card-info">
      <br />
      <div style={{ position: "relative" }}>
        <MaterialTable
          title={<CommissionDetailsList />}
          data={data}
          columns={columns}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
          }}
        />
      </div>
      {error && (
        <div style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
          {error}
        </div>
      )}
    </div>
  );
}

export default Commission;
