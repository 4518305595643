import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";

const PointsRejectedReport = () =>{
  return(
    <div style = {{fontSize: '18px', fontWeight: 'bold'}}>Points Rejected Report</div>
  );
}

function PointRejected() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]); 
  // const columns = [
  //   { title: "Serial No", render: (rowData) => rowData.tableData.id + 1 },
  //   { title: "From User", field: "FromAccountName" },
  //   { title: " To User ", field: "ToAccountName" },
   
  //   { title: "Amount", field: "Amount",filtering:true },
  //   {
  //     title: " Rejected Time",
  //     render: rowData => rowData.RejectionDate?moment(rowData.RejectionDate).format('YYYY-M-D h:mm:ss'):'Not login yet',
  //     filtering:true,
  //   }, 
  // ];
  const sortedData = data.sort((a, b) => new Date(b.RejectionDate) - new Date(a.RejectionDate));

const columns = [
  { title: "Serial No", render: (rowData) => rowData.tableData.id + 1, headerStyle: { fontSize: '15px' } },
  { title: "From User", field: "FromAccountName", headerStyle: { fontSize: '15px' } },
  { title: "To User", field: "ToAccountName", headerStyle: { fontSize: '15px' } },
  { title: "Amount", field: "Amount", filtering: true , headerStyle: { fontSize: '15px' }},
  {
    title: "Rejected Time", headerStyle: { fontSize: '15px' },
    render: rowData => rowData.RejectionDate ? moment(rowData.RejectionDate).format('YYYY-MM-DD hh:mm:ss A') : 'Not login yet',
    filtering: true,
  },
];
  //get Agents
  const getReports = async () => {
    await axios({
      method: "GET",
      url: `${apiBaseURL}/user/Rejectdetail`,

      //url: `${apiBaseURL}/user/PointRejected`,
      // data: user,
      // headers: {"Authorization" : `Bearer ${authToken}`}
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        } else {
         
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getReports();
  }, []);

  return ( 
      <><div className="card card-outline card-info">
      <MaterialTable
        title={<PointsRejectedReport />}
        data={data}
        columns={columns} 
      />
    </div> 
      </>
  );
}
export default PointRejected;
