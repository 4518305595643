import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";

function WalletTransaction() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);

  const columns = [
    { title: "Serial No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Sender", field: "sender" },
    { title: "Point", field: "point", filtering: true },
    {
      title: "Transaction Date",
      render: (rowData) =>
        rowData.createdat
          ? moment(rowData.createdat).format("YYYY-M-D h:mm:ss")
          : "Not logged in yet",
      filtering: true,
    },
    {
      title: "Status",
      render: (rowData) => (
        <span style={{ color: "green" }}>
          {rowData.message === "opening balance" ? "Opening Balance" : "null"}
        </span>
      ),
    },
  ];

  const getReports = async () => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/WalletTransaction`, {
        receiverId: "RL00000", 
      });
      if (response.status === 200) {
        setData(response.data);
      } else {
        console.log("Error fetching data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      <div className="card card-outline card-info">
        <MaterialTable
          title="Wallet Transactions Report"
          data={data}
          columns={columns}
        />
      </div>
    </>
  );
}

export default WalletTransaction;
