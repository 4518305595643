import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import '../../style/Contact.css'
import 'react-datepicker/dist/react-datepicker.css'
import clubImage from '../../image/cards/club.png';
import spadeImage from '../../image/cards/spade.png';
import diamondImage from '../../image/cards/diamond.jpg';
import heartImage from '../../image/cards/heart.png';
 
import axios from "axios";
// import { apiBaseURL } from "../../config";
import moment from 'moment'
let apiBaseURL  = "https://royalluck.club:5000"
// import { authToken } from "../../authToken";

const GamesPlayHistory = () =>{
  return(
    <div style = {{fontSize: '18px', fontWeight: 'bold'}}>
      Games Play History
    </div>
  );
}



function AndarBaharGameHistory() {
    const [data, setData] = useState([]);

    // get Agents
    const gameReports = async () => {
      await axios
        .get(`${apiBaseURL}/user/AndarBaharGamePlayHistory`)
        .then(function (response) {
          if (response.data.status === 200) {
            let datas = response.data.data;
            
            
            setData(response.data.data);
          }
        })
        .catch(function (error) { 
        });
    };
   

    const mapCardNumberToNameAndSuit = (number) => {
      const cardNames = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
      const suits = ['diamond', 'heart' ,'club', 'spade'];
      
      const suitIndex = Math.floor(number / 13);
      const cardName = cardNames[number % 13];
      const suit = suits[suitIndex];
      
      return `${cardName}`;
    };
    //get Agents
   
    const columns = [
      { title: "Serial No", render: rowData => rowData.tableData.id + 1, headerStyle: { fontSize: '18px' } },
      { title: "Round count", field: "RoundCount", headerStyle: { fontSize: '18px' } },
      { 
        title: "Joker", headerStyle: { fontSize: '18px' },
        field: "finalresult",
        render: rowData => {
          // Conditionally render an image based on the value of finalResult
          let imageSrc;
          if(rowData.finalresult < 13) {
            imageSrc = diamondImage;
          }else if(rowData.finalresult >= 13 && rowData.finalresult < 26){
            imageSrc = heartImage;
          }else if(rowData.finalresult >= 26 && rowData.finalresult < 39){
            imageSrc = clubImage;
          }else if(rowData.finalresult >= 39 && rowData.finalresult < 52){
            imageSrc = spadeImage;
          }
          const cardNumber = rowData.finalresult; // Assuming finalresult contains card number
          const cardNameAndSuit = mapCardNumberToNameAndSuit(cardNumber);
          // Render the image if imageSrc is available, otherwise render null
          return (
            <div>
              {imageSrc && <img src={imageSrc} alt={rowData.finalresult} style={{ width: '30px', height: '30px' }} />}
              &nbsp;{cardNameAndSuit}
            </div>
          )
        }
      },
      { title: "Winning Side", headerStyle: { fontSize: '18px' }, field: "Cardresult",
         render: rowData => {
          return (
               rowData.Cardresult === 1 ? "Bahar" : "Andar"
          )
         }
       },
      { title: "Date & Time", headerStyle: { fontSize: '18px' }, render: rowData => moment(rowData.playedtime).format("DD-MM-YYYY h:mm:ss ") }, 

    ];
  
    useEffect(() => {
      gameReports(); 
    }, []);
    return (
        <>
        <div  className="card card-outline card-info">
      <MaterialTable
        title={<GamesPlayHistory/>}
        data={data}
        columns={columns}
        options={{ actionsColumnIndex: -1 }} 
      />
    </div>
        </>
    )
}
export default AndarBaharGameHistory;
