import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import "../../style/Login.css";
import Navbar from "./Navbar";

function Triplegame() {
  let apiBaseURL = "https://royalluck.club:5000";

  const [data, setData] = useState([]);
  const [value1, setvalue1] = useState("");
  const [value2, setvalue2] = useState("");
  const [value3, setvalue3] = useState("");
  const [islock, setislock] = useState(true);
  const [email, setemail] = useState("");
  const [min, setmin] = useState(0);
  const [max, setmax] = useState(0);
  const [minname, setminname] = useState("");
  const [maxname, setmaxname] = useState("");

  const [password, setpassword] = useState("");

  const [index, setindex] = useState([""]);
  const [detail, setdetail] = useState([]);
  const [tripleTimer, settripleTimer] = useState(null);

  // get Agents
  const gameReports = () => {
    axios
      .get(`${apiBaseURL}/user/triplechancedetail`)
      .then(function (response) {
        if (response.data.status === 200) {
          const dataWithTotalBet = response.data.data.map((item) => {
            const totalBetAmount =
              (item.maxsingleBetAmount !== "N/A"
                ? item.maxsingleBetAmount
                : 0) +
              (item.maxdoubleBetAmount !== "N/A"
                ? item.maxdoubleBetAmount
                : 0) +
              (item.maxtripleBetAmount !== "N/A" ? item.maxtripleBetAmount : 0);
            return { ...item, totalBetAmount };
          });
          console.log(dataWithTotalBet, "tttttt");
          setdetail(dataWithTotalBet);
        }
      })
      .catch(function (error) {
        console.error("Error fetching data", error);
      });
  };

  const getTimer = async () => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/user/getTripleChanceTimerValue`
      );
      if (response.data.timerValue !== undefined) {
        settripleTimer(response.data.timerValue);
      }
    } catch (error) {
      console.error("Error fetching timer value:", error);
    }
  };
  useEffect(() => {
    getTimer();

    const interval = setInterval(() => {
      getTimer();
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleAdd = (i) => {
    setindex([...index, i]);
  };

  const handleemail = (e) => {
    setemail(e.target.value);
  };

  const handlepassword = (e) => {
    setpassword(e.target.value);
  };

  const onhandle = (e) => {
    e.preventDefault();
    if (email == "lock@admin.com" && password == "12345") {
      setislock(false);
    } else {
      setislock(true);
    }
  };

  const columns = [
    { title: "Single", field: "singleNo", headerStyle: { fontWeight: "bold" } },
    { title: "Double", field: "double", headerStyle: { fontWeight: "bold" } },
    { title: "Triple", field: "tripleNo", headerStyle: { fontWeight: "bold" } },
  ];

  const columns1 = [
    {
      title: "PlayerID",
      field: "playername",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Round_Count",
      field: "roundcount",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Single_No",
      field: "maxsingleBetNumber",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Bet_Amount (Single)",
      field: "maxsingleBetAmount",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Double_No",
      field: "maxdoubleBetNumber",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Bet_Amount (Double)",
      field: "maxdoubleBetAmount",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Triple_No",
      field: "maxtripleBetNumber",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Bet_Amount (Triple)",
      field: "maxtripleBetAmount",
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Total_Bet_Amount",
      field: "totalBetAmount",
      headerStyle: { fontWeight: "bold" },
    },
  ];

  useEffect(() => {
    axios
      .get(`${apiBaseURL}/user/gamerunningtriplechance`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email != "admin@admin.com";
          });
          console.log("result", result);

          var temp = [];

          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("calling in everyone");
      axios
        .get(`${apiBaseURL}/user/gamerunningtriplechance`)

        .then(function (response) {
          if (response.data.status === 200) {
            console.log(response.data, "data");
            const result = response.data.data.filter((item) => {
              return item.email != "admin@admin.com";
            });
            setData(result);
            console.log(result, "Result");
            setData(result);
            var obj = result[0];
            var name = [];
            var arr = Object.keys(obj).map(function (key) {
              if (typeof obj[key] !== "object" && obj[key] !== "undefined") {
                name.push(key);
                return obj[key];
              } else {
                return null;
              }
            });
            console.log(name, "namearr");
            var res = arr.filter((elements) => {
              return elements !== null;
            });

            console.log(res, "res");
            var min = Math.min.apply(null, res);
            var max = Math.max.apply(null, res);
            setmin(min);
            setmax(max);
            setminname(name[res.indexOf(min)]);
            setmaxname(name[res.indexOf(max)]);

            // setData(response.data.data);
          }
        })
        .catch(function (error) {
          // history.push("/login")
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    axios
      .get(`${apiBaseURL}/user/getAdmintriplechance`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          setvalue1(response.data.data.value1);
          setvalue2(response.data.data.value2);
          setvalue3(response.data.data.value3);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
    gameReports();
  }, []);

  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  //checkbox section
  //desktop view
  const [innerWinSelectedCheckbox, setInnerWinSelectedCheckbox] = useState("");
  const [middleWinSelectedCheckbox, setMiddleWinSelectedCheckbox] =
    useState("");
  const [outerWinSelectedCheckbox, setOuterWinSelectedCheckbox] = useState("");

  const handleInnerWinCheckboxChange = (value) => {
    if (innerWinSelectedCheckbox === value) {
      setInnerWinSelectedCheckbox("");
    } else {
      setInnerWinSelectedCheckbox(value);
    }
  };

  const handleMiddleWinCheckboxChange = (value) => {
    if (middleWinSelectedCheckbox === value) {
      setMiddleWinSelectedCheckbox("");
    } else {
      setMiddleWinSelectedCheckbox(value);
    }
  };

  const handleOuterWinCheckboxChange = (value) => {
    if (outerWinSelectedCheckbox === value) {
      setOuterWinSelectedCheckbox("");
    } else {
      setOuterWinSelectedCheckbox(value);
    }
  };

  //mobile view
  const [innerWinSelectedDropDown, setInnerWinSelectedDropDown] = useState("");
  const [middleWinSelectedDropDown, setMiddleWinSelectedDropDown] =
    useState("");
  const [outerWinSelectedDropDown, setOuterWinSelectedDropDown] = useState("");

  const handleInnerWinDropDownChange = (event) => {
    const value = event.target.value;
    if (innerWinSelectedDropDown === value) {
      setInnerWinSelectedDropDown("");
    } else {
      setInnerWinSelectedDropDown(value);
    }
  };

  const handleMiddleWinDropDownChange = (event) => {
    const value = event.target.value;

    if (middleWinSelectedDropDown === value) {
      setMiddleWinSelectedDropDown("");
    } else {
      setMiddleWinSelectedDropDown(value);
    }
  };

  const handleOuterWinDropDownChange = (event) => {
    const value = event.target.value;

    if (outerWinSelectedDropDown === value) {
      setOuterWinSelectedDropDown("");
    } else {
      setOuterWinSelectedDropDown(value);
    }
  };

  //clear button
  const handleClearButtonClick = (event) => {
    event.preventDefault();

    setInnerWinSelectedCheckbox("");
    setMiddleWinSelectedCheckbox("");
    setOuterWinSelectedCheckbox("");
    setvalue1(-1);
    setvalue2(-1);
    setvalue3(-1);
    axios
      .post("https://royalluck.club:5000/user/Admintriplechance", {
        value1: -1,
        value2: -1,
        value3: -1,
      })

      .then((data) => {
        console.log(data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Reset",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // clear button end
  // chebox section end

  //desktop view
  const handleSaveButtonClick = (event) => {
    event.preventDefault();
    setvalue1(innerWinSelectedCheckbox);
    setvalue2(middleWinSelectedCheckbox);
    setvalue3(outerWinSelectedCheckbox);

    axios
      .post("https://royalluck.club:5000/user/Admintriplechance", {
        value1: innerWinSelectedCheckbox,
        value2: middleWinSelectedCheckbox,
        value3: outerWinSelectedCheckbox,
      })

      .then((data) => {
        console.log(data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: "custom-swal-modal",
          },
          width: "70%",
          padding: "20px",
          background: "white",
          fontSize: "16px",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //mobile view
  const handleSaveButtonClick1 = (event) => {
    event.preventDefault();
    setvalue1(innerWinSelectedDropDown);
    setvalue2(middleWinSelectedDropDown);
    setvalue3(outerWinSelectedDropDown);

    axios
      .post("https://royalluck.club:5000/user/Admintriplechance", {
        value1: innerWinSelectedDropDown,
        value2: middleWinSelectedDropDown,
        value3: outerWinSelectedDropDown,
      })

      .then((data) => {
        console.log(data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: "custom-swal-modal",
          },
          width: "70%",
          padding: "20px",
          background: "white",
          fontSize: "16px",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="col-md-12">
      <div className="card card-outline card-info">
        <div className="borders">
          <Navbar />
        </div>

        <div className="card-body">
          <div className="row">
            {/* First Game */}
            <div className="col-md-3">
              <div className="card card-outline card-warning">
                <div className="d-flex">
                  <div
                    className="p-2"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <h2 className="card-title" style={{ fontSize: "16px" }}>
                      Triple Chance Game
                    </h2>

                    <br />
                    <hr />
                    <div style={{ overflow: "auto hidden" }}>
                      <table>
                        <tr>
                          <th className="col-md-4">Single</th>
                          <th className="col-md-4">Double</th>
                          <th className="col-md-4">Triple</th>
                        </tr>
                        <tbody>
                          <tr>
                            <td className="pt-2">
                              {value1 == -1 ? "none" : value1}
                            </td>
                            <td className=" pt-2">
                              {value2 == -1 ? "none" : value2}
                            </td>
                            <td className=" pt-2">
                              {value3 == -1 ? "none" : value3}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <form className="shadow-sm p-2">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <button
                        className="btn btn-primary mr-2"
                        onClick={handleClearButtonClick}
                      >
                        Clear
                      </button>
                    </div>

                    {/* Desktop view */}
                    <div className="col-md-6 mb-2">
                      <button
                        className="btn btn-success position-relative div2 ml-1 b1"
                        onClick={handleSaveButtonClick}
                      >
                        Save
                      </button>
                    </div>

                    {/* Mobile view */}
                    <div className="col-md-6 mb-2 ml-1">
                      <button
                        className="btn btn-success position-relative div2 b2"
                        onClick={handleSaveButtonClick1}
                      >
                        Save
                      </button>
                    </div>
                  </div>

                  <div className=" row">
                    <div className="col-md-4">Single Win</div>
                    <div className="col-md-4">Double Win</div>
                    <div className="col-md-4">Triple Win</div>
                  </div>

                  {/* desktop view */}
                  <div className="desktopView">
                    <div className="row">
                      <div
                        style={{ maxWidth: "100%", overflow: "hidden auto" }}
                      >
                        <ul className="list-group custome-ul-class two-column-list">
                          <div className="col-md-4">
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin0"
                                  value="0"
                                  checked={innerWinSelectedCheckbox === "0"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("0")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin0"
                                >
                                  0
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin1"
                                  value="1"
                                  checked={innerWinSelectedCheckbox === "1"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("1")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin1"
                                >
                                  1
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin2"
                                  value="2"
                                  checked={innerWinSelectedCheckbox === "2"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("2")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin2"
                                >
                                  2
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin3"
                                  value="3"
                                  checked={innerWinSelectedCheckbox === "3"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("3")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin3"
                                >
                                  3
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin4"
                                  value="4"
                                  checked={innerWinSelectedCheckbox === "4"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("4")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin4"
                                >
                                  4
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin5"
                                  value="5"
                                  checked={innerWinSelectedCheckbox === "5"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("5")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin5"
                                >
                                  5
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin6"
                                  value="6"
                                  checked={innerWinSelectedCheckbox === "6"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("6")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin6"
                                >
                                  6
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin7"
                                  value="7"
                                  checked={innerWinSelectedCheckbox === "7"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("7")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin7"
                                >
                                  7
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin8"
                                  value="8"
                                  checked={innerWinSelectedCheckbox === "8"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("8")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin8"
                                >
                                  8
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin9"
                                  value="9"
                                  checked={innerWinSelectedCheckbox === "9"}
                                  onChange={() =>
                                    handleInnerWinCheckboxChange("9")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="innerWin9"
                                >
                                  9
                                </label>
                              </div>
                            </li>
                          </div>
                        </ul>

                        <ul className="list-group custome-ul-class two-column-list">
                          <div className="col-md-4">
                            {/* Checkbox for value 0 */}

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin10"
                                  value="0"
                                  checked={middleWinSelectedCheckbox === "0"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("0")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin10"
                                >
                                  0
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 1 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin11"
                                  value="1"
                                  checked={middleWinSelectedCheckbox === "1"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("1")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin11"
                                >
                                  1
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 2 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin12"
                                  value="2"
                                  checked={middleWinSelectedCheckbox === "2"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("2")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin12"
                                >
                                  2
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 3 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin13"
                                  value="3"
                                  checked={middleWinSelectedCheckbox === "3"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("3")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin13"
                                >
                                  3
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 4 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin14"
                                  value="4"
                                  checked={middleWinSelectedCheckbox === "4"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("4")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin14"
                                >
                                  4
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 5 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin15"
                                  value="5"
                                  checked={middleWinSelectedCheckbox === "5"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("5")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin15"
                                >
                                  5
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 6 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin16"
                                  value="6"
                                  checked={middleWinSelectedCheckbox === "6"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("6")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin16"
                                >
                                  6
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 7 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin17"
                                  value="7"
                                  checked={middleWinSelectedCheckbox === "7"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("7")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin17"
                                >
                                  7
                                </label>
                              </div>
                            </li>

                            {/* Checkbox for value 8 */}
                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin18"
                                  value="8"
                                  checked={middleWinSelectedCheckbox === "8"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("8")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin18"
                                >
                                  8
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="middleWin"
                                  id="middleWin19"
                                  value="9"
                                  checked={middleWinSelectedCheckbox === "9"}
                                  onChange={() =>
                                    handleMiddleWinCheckboxChange("9")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="middleWin19"
                                >
                                  9
                                </label>
                              </div>
                            </li>
                          </div>
                        </ul>

                        <ul className="list-group custome-ul-class two-column-list">
                          <div className="col-md-4">
                            {/* Checkbox for value 0 */}

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin20"
                                  value="0"
                                  checked={outerWinSelectedCheckbox === "0"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("0")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin20"
                                >
                                  0
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin21"
                                  value="1"
                                  checked={outerWinSelectedCheckbox === "1"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("1")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin21"
                                >
                                  1
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin22"
                                  value="2"
                                  checked={outerWinSelectedCheckbox === "2"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("2")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin22"
                                >
                                  2
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin23"
                                  value="3"
                                  checked={outerWinSelectedCheckbox === "3"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("3")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin23"
                                >
                                  3
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin24"
                                  value="4"
                                  checked={outerWinSelectedCheckbox === "4"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("4")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin24"
                                >
                                  4
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin25"
                                  value="5"
                                  checked={outerWinSelectedCheckbox === "5"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("5")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin25"
                                >
                                  5
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin26"
                                  value="6"
                                  checked={outerWinSelectedCheckbox === "6"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("6")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin27"
                                >
                                  6
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin27"
                                  value="7"
                                  checked={outerWinSelectedCheckbox === "7"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("7")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin27"
                                >
                                  7
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin28"
                                  value="8"
                                  checked={outerWinSelectedCheckbox === "8"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("8")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin28"
                                >
                                  8
                                </label>
                              </div>
                            </li>

                            <li className="list-group-items">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="outerWin"
                                  id="outerWin29"
                                  value="9"
                                  checked={outerWinSelectedCheckbox === "9"}
                                  onChange={() =>
                                    handleOuterWinCheckboxChange("9")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outerWin29"
                                >
                                  9
                                </label>
                              </div>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* Mobile view */}
              <div className="mobileView col-sm-12">
                <select
                  className="col-md-12"
                  style={{
                    width: "190px",
                    marginLeft: "-2px",
                    marginTop: "10px",
                  }}
                  value={innerWinSelectedDropDown}
                  onChange={handleInnerWinDropDownChange}
                >
                  <option
                    className="form-check-input"
                    type="checkbox"
                    name="innerWin"
                    id="innerWin0"
                    value=""
                  >
                    Select Single number
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin0"
                    value="0"
                  >
                    0
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin1"
                    value="1"
                  >
                    1
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin2"
                    value="2"
                  >
                    2
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin3"
                    value="3"
                  >
                    3
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin4"
                    value="4"
                  >
                    4
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin5"
                    value="5"
                  >
                    5
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin6"
                    value="6"
                  >
                    6
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin7"
                    value="7"
                  >
                    7
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin8"
                    value="8"
                  >
                    8
                  </option>

                  <option
                    className="form-check-input"
                    name="innerWin"
                    id="innerWin9"
                    value="9"
                  >
                    9
                  </option>
                </select>

                {/* middleWin */}
                <select
                  value={middleWinSelectedDropDown}
                  className="col-md-12"
                  onChange={handleMiddleWinDropDownChange}
                  style={{
                    width: "190px",
                    marginLeft: "-2px",
                    marginTop: "10px",
                  }}
                >
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin10"
                    value=""
                  >
                    Select Double number
                  </option>

                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin10"
                    value="0"
                  >
                    0
                  </option>

                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin11"
                    value="1"
                  >
                    1
                  </option>

                  {/* Checkbox for value 2 */}
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin12"
                    value="2"
                  >
                    2
                  </option>

                  {/* Checkbox for value 3 */}
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin13"
                    value="3"
                  >
                    3
                  </option>

                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin14"
                    value="4"
                  >
                    4
                  </option>

                  {/* Checkbox for value 5 */}
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin15"
                    value="5"
                  >
                    5
                  </option>

                  {/* Checkbox for value 6 */}
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin16"
                    value="6"
                  >
                    6
                  </option>

                  {/* Checkbox for value 7 */}
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin17"
                    value="7"
                  >
                    7
                  </option>

                  {/* Checkbox for value 8 */}
                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin18"
                    value="8"
                  >
                    8
                  </option>

                  <option
                    className="form-check-input"
                    name="middleWin"
                    id="middleWin19"
                    value="9"
                  >
                    9
                  </option>
                </select>

                <select
                  value={outerWinSelectedDropDown}
                  className="col-md-12"
                  onChange={handleOuterWinDropDownChange}
                  style={{
                    width: "190px",
                    marginLeft: "-2px",
                    marginTop: "10px",
                  }}
                >
                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin20"
                    value=""
                  >
                    Select Triple number
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin20"
                    value="0"
                  >
                    0
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin21"
                    value="1"
                  >
                    1
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin22"
                    value="2"
                  >
                    2
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin23"
                    value="3"
                  >
                    3
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin24"
                    value="4"
                  >
                    4
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin25"
                    value="5"
                  >
                    5
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin26"
                    value="6"
                  >
                    6
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin27"
                    value="7"
                  >
                    7
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin28"
                    value="8"
                  >
                    8
                  </option>

                  <option
                    className="form-check-input"
                    name="outerWin"
                    id="outerWin29"
                    value="9"
                  >
                    9
                  </option>
                </select>
              </div>
            </div>

            {/* First End */}

            <div className="col-md-9">
              <h3>
                Timer: {tripleTimer !== null ? tripleTimer : "Loading..."}
              </h3>
              <h4>
                Lowest:{minname}
                <h5>{min}</h5>
              </h4>
              <h4>
                Highest:{maxname}
                <h5>{max}</h5>
              </h4>

              <MaterialTable
                title="Current Betting "
                data={data}
                columns={columns}
              />
              <MaterialTable
                title="Current Betting User List"
                data={detail}
                columns={columns1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Triplegame;
