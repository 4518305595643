import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";

function AddMasterId() {
  //let apiBaseURL = "";
  let apiBaseURL = "https://royalluck.club:5000";
  var t = new Date()
  var MA = "MA" + t.getTime();
  const sessionData = sessionStorage.getItem("token");
  const [values, setValues] = useState({
    full_name: "",
    password: "",
    email: "",
commission:"",
  });
  const [destriData, setDestriData] = useState([]);
  const [masterpassword, setPassword] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedSuperMaster, setSelectedSuperMaster] = useState('');
  const [superMasters, setSuperMasters] = useState([]);
  const [commission, setcommission] = useState(0);

  const generatePassword = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getPass`);
      if (response.data.statusCode === 200) {
        setPassword(response.data.password);
      } else {
        Swal.fire(`Error: ${response.data.message}`, "error");
      }
    } catch (error) {
      Swal.fire(`Something went wrong!`, "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { full_name, password } = values;
  
    const user = {
      password: masterpassword,
      full_name,
      email: destriData,
      role_id: 3,
      super_master_email: selectedSuperMaster,
      commission,
    };
    if(parseInt(commission)>=0&&parseInt(commission)<=100 ){
  
    try {
      const response = await axios.post(`${apiBaseURL}/auth/adduserbyadmin`, user, {
        headers: { Authorization: `Bearer ${sessionData.token}` },
      });
  
      if (response.data.status === 200) {
      
        try {
          const childPlayerIdResponse = await axios.post(
            `${apiBaseURL}/auth/getChildPlayerid`,
            { email: destriData },
            {
              headers: { Authorization: `Bearer ${sessionData.token}` },
            }
          );
        
          if (childPlayerIdResponse.data.status === 200) {
            const playersData = childPlayerIdResponse.data.data;
            const playerInfo = `
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Password</th>
                </tr>
              </thead>
              <tbody>
                ${playersData.map(player => `
                  <tr>
                    <td>${player.email}</td>
                    <td>${player.password}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          `;        
            Swal.fire({
              icon: 'success',
              title: 'Child Player IDs and Passwords',
              html: `<div style="background-color: white; color: black; ">
                      ${playerInfo}
                    </div>`,
              customClass: {
                title: 'swal-title',
                content: 'swal-text',
              },
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to retrieve child player IDs and passwords.',
              customClass: {
                title: 'swal-title',
                content: 'swal-text',
              },
            });
          }
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while retrieving child player IDs and passwords.',
            customClass: {
              title: 'swal-title',
              content: 'swal-text',
            },
          });
        }
        

        
  
        setValues({
          full_name: '',
          password: '',
          email: '',
          commission:""
        });
      } else {
        if (response.data.status === 401) {
          sessionStorage.removeItem('token');
          window.location.reload();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Oops...',
            text: `${response.data.message} !`,
            showConfirmButton: false,
            timer: 1700,
          });
        }
      }
    } catch (error) {
      console.error('API Error:', error);
      Swal.fire('Something went wrong!', 'error');
    }
  }else{
    Swal.fire({
      title: 'Commission should be between 0 to 100',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  }}
  

  //get Agents
  const getAgents = async () => {
    await axios
      .get(`${apiBaseURL}/user/getPlayerId3`)
      .then(function (response) {
        if (response.data.status === 200) {
          setDestriData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };
  //
  const getSupermaster = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getSuperMasterData`);
      if (response.data.status === 200) {
        console.log(response.data, "data");
        setSuperMasters(response.data.data);
      }
    } catch (error) {

      console.error('Error fetching Super Masters: ', error);
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  useEffect(() => {
    getSupermaster();
    getAgents();
    generatePassword();
  }, []);

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-outline card-info">
          <div className="card-header bg-info text-white">
            <h3 className="card-title">
              <i className="fa-solid fa-user-tie mr-2" /> Add New Master Id
            </h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="superMaster">Select Super Master</label>
                <select
                  id="superMaster"
                  name="superMaster"
                  className="form-control"
                  value={selectedSuperMaster}
                  onChange={(e) => setSelectedSuperMaster(e.target.value)}
                  required
                >
                  <option value="" disabled>Select Super Master</option>
                  {superMasters.map((superMaster) => (
                    <option key={superMaster.email} value={superMaster.email}>
                      {superMaster.email}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="masterId">MasterId ID</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={destriData}
                      readOnly
                      style={{
                        cursor: 'not-allowed',

                      }}
                    />
                  </div>
                </div>
               
          



                <div className="form-container">
                <label > Add Commission</label>
                <input
                  type="text"
                  className="form-control"
                //  defaultValue={destriData}
                //value={values.commission}
                onChange={(event)=>setcommission(event.target.value)}
                required
                />
              </div>

                <label htmlFor="inputPassword">Enter Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={masterpassword}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                </div>
              </div>









              <div className="row">
                <div className="col-6">
                  <button className="btn btn-secondary btn-block" type="reset">
                    Reset
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-success btn-block" type="submit">
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

}
export default AddMasterId;
