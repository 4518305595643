import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";

const UserLocation = () =>{
  return(
    <div style = {{fontSize: '18px', fontWeight: 'bold'}}>User Location</div>
  );
}

function CheckLocation() {
  const apiBaseURL = "https://royalluck.club:5000";

  const [data, setData] = useState([]);

  const getIp = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getUserIp`);
      if (response.data.results.length > 0) {
        const filteredData = response.data.results.filter(
          (item) => item.userid !== "admin"
        );
        setData(filteredData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    { title: "User ID", field: "userid", headerStyle: { fontSize: '15px' } },
    { title: "IP Address", field: "ipaddress", headerStyle: { fontSize: '15px' } },
    { title: "City", field: "city", headerStyle: { fontSize: '15px' } },
  //  { title: "Created At", field: "created_at", render: rowData => moment(rowData.created_at).format("YYYY-MM-DD HH:mm:ss") }
  ];

  useEffect(() => {
    getIp();
  }, []);

  return (
    <div className="card card-outline card-info">
      <MaterialTable
        title={<UserLocation />}
        data={data}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
      />
    </div>
  );
}

export default CheckLocation;
