import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import "../../style/Contact.css";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

const PointTransferHistory = () => {
  return (
    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
      Point Transfer History
    </div>
  );
};

function Transferable() {
  const apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);
  const loginEmail = sessionStorage.getItem("loginEmail");

  const getPlayers = async () => {
    const response = await axios.post(
      `${apiBaseURL}/transaction/pointTransfer`,
      {
        emailId: loginEmail,
      }
    );
    const responseData = response.data.data;
    if (responseData && Array.isArray(responseData)) {
      setData(responseData);
    }
  };

  const changeStatus = async (distributor, status, message) => {
    Swal.fire({
      title: `Are you sure? Want to ${message}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${message} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        const statusCode = 0;
        const updateData = {
          active: statusCode,
          distributor_id: distributor,
        };
        axios
          .put(`${apiBaseURL}/api/users/changeStatusDistributor`, updateData)
          .then((response) => {
            if (response.data.status === 200) {
              getPlayers();
              Swal.fire(
                `${response.data.message}!`,
                `User status have been ${response.data.message}`,
                "success"
              );
            } else {
              Swal.fire(`${response.data.message}!`, "error");
            }
          })
          .catch(() => {
            Swal.fire(`Something Went wrong!`, "error");
          });
      }
    });
  };

  const deleteUser = async (distributor) => {
    Swal.fire({
      position: "top-start",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${apiBaseURL}/api/users/deleteDistributor/${distributor}`)
          .then((response) => {
            if (response.data.status === 200) {
              getPlayers();
              Swal.fire(
                `${response.data.message}!`,
                `User have been ${response.data.message}`,
                "success"
              );
            } else {
              Swal.fire(`${response.data.message}!`, "error");
            }
          })
          .catch(() => {
            Swal.fire(`Something Went wrong!`, "error");
          });
      }
    });
  };

  const sortedData = data.sort(
    (a, b) => new Date(b.createdat) - new Date(a.createdat)
  );

  const columns = [
    {
      title: "Sl No.",
      render: (rowData) => rowData.tableData.id + 1,
      headerStyle: { fontSize: "18px" },
    },
    {
      title: "From User",
      field: "senderId",
      headerStyle: { fontSize: "18px" },
      render: (rowData) =>
        rowData.senderId === "admin@admin.com"
          ? "Admin"
          : rowData.senderId === "RL0000"
          ? "Company"
          : rowData.senderId,
    },

    {
      title: "To User",
      field: "receiverId",
      headerStyle: { fontSize: "18px" },
      render: (rowData) =>
        rowData.receiverId === "RL0000" ? "Company" : rowData.receiverId,
    },

    { title: "Point", field: "point", headerStyle: { fontSize: "18px" } },
    {
      title: "Transaction Date",
      headerStyle: { fontSize: "18px" },
      render: (rowData) => rowData.dateTime || "Invalid Date",
      filtering: true,
    },
  ];

  useEffect(() => {
    getPlayers();
  }, []);

  return (
    <div className="card card-outline card-info">
      <MaterialTable
        title={<PointTransferHistory />}
        data={data}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
      />
    </div>
  );
}

export default Transferable;
