import React from 'react'
import { BrowserRouter as Redirect, Switch, Route } from 'react-router-dom'
 
import Home from '../../views/Dashboard/Home';
import Triplegame from '../../views/gamebet/Triplegame';

import ViewMasterId from '../../views/agents/ViewMasterId';

import ViewSuperMaster from '../../views/Super/ViewSuperMaster';
import Commission from '../../views/points/Commission';
import UpcomingCommission from '../../views/points/UpcomingCommission';

import AddMasterId from '../../views/agents/AddMasterId';
import AddSuperMaster from '../../views/Super/AddSuperMaster';

import AddNewPlayer from '../../views/players/AddNewPlayer';
import PlayersList from '../../views/players/PlayersList';

import PointTransferred from '../../views/points/PointTransferredSuper';
import PointReceived from '../../views/points/PointReceived';
import PointCancel from '../../views/points/PointCancel';
import PointRejected from '../../views/points/PointRejected';
import Transferable from '../../views/points/Transferable';
import Receivable from '../../views/points/Receivable';

import RoulletGame from '../../views/gamebet/RoulletGame';
import ShowCurrentBet from '../../views/gamebet/ShowCurrentBet';
import FunTargetGame from '../../views/gamebet/FunTargetGame';
import SevenUpDownGame from '../../views/gamebet/SevenUpDownGame';
import AndarBaharGames from '../../views/gamebet/AndarBaharGame';



import PointTransferredMaster from '../../views/points/PointTransferredMaster';
import PointTransferredPlayer from '../../views/points/PointTransferredPlayer';
import PointTransferredSuper from '../../views/points/PointTransferredSuper';
 


import GameReport from '../../views/points/GameReport';

import DailyStatus from '../../views/points/DailyStatus';

import GameHistory from '../../views/game/GameHistory';
import AndarBaharGameHistory from '../../views/game/AndarBaharGameHistory';
import RoulletGameHistory from '../../views/game/RoulletGameHistory';
import FunTargetGameHistory from '../../views/game/FunTargetGameHistory';
import TripleChanceGameHistory from '../../views/game/TripleChanceGameHistory';
import SevenUpDownGameHistory from '../../views/game/SevenUpDownGameHistory';



import PlayerHistory from '../../views/players/PlayerHistrory';
import ChangePassword from '../../views/settings/ChangePassword';
import ChangeUserPassword from '../../views/settings/ChangeUserPassword';
import TransactionHistory from '../../views/payments/TransactionHistory';
import WithdrawRequest from '../../views/payments/WithdrawRequest';
import Cards from '../../views/Cards/Cards'
import UsersList from '../../views/Users/UsersList'
import ViewChildId from '../../views/players/viewChildId';

import GameDetailsModal from '../../views/players/GameDetailsModal';
import debitPoints from '../../views/points/debitPoints';

import WalletTransaction from '../../views/points/wallet_transaction';

import ManagePoints from '../../views/points/ManagePoints'
import CheckCommission from '../../views/points/check_commission'
import CheckLocation from '../../views/Users/checkLocation'
import '../../style/AppContent.css';


export default function AppContents() {
  return (
    <section className="content sec1">
    <div className="container-fluid" >
        <Route path="/" exact component={Home} /> 
        <Route path="/DebitPoint" exact component={debitPoints} /> 
        <Route path="/checkLocation" exact component={CheckLocation} /> 
        <Route path="/SuperMaster" exact component={ViewSuperMaster} /> 
        <Route path="/GameDetailsModal" exact component={GameDetailsModal} /> 
        <Route path="/AddMaster" exact component={AddSuperMaster} /> 
        
        <Route path="/MasterId" exact component={ViewMasterId} /> 
        <Route path="/AdMasterId" exact component={AddMasterId} /> 
        <Route path="/PlayersList" exact component={PlayersList} /> 
        <Route path="/AddnewPlayer" exact component={AddNewPlayer} /> 
        <Route path="/Transactions" exact component={TransactionHistory} /> 

        <Route path="/CommissionDetail" exact component={Commission} /> 
        <Route path="/UpcomingCommission" exact component={UpcomingCommission} /> 

        <Route path="/PointTransfer" exact component={PointTransferred} /> 
        <Route path="/PointReceive" exact component={PointReceived} /> 
        <Route path="/PointReject" exact component={PointRejected} /> 
        <Route path="/PointCancell" exact component={PointCancel} /> 
        <Route path="/Receivabled" exact component={Receivable} /> 
        <Route path="/Transferabled" exact component={Transferable} /> 
        <Route path="/wallet_transaction" exact component={WalletTransaction} /> 
        <Route path="/manage_points" exact component={ManagePoints} /> 
        
        

        

        <Route path="/GameReports" exact component={GameReport} /> 
        <Route path="/DailyStatuss" exact component={DailyStatus} /> 


         <Route path="/pointPlayer" exact component={PointTransferredPlayer} /> 
        <Route path="/pointSuperMaster" exact component={PointTransferredSuper} /> 
        <Route path="/pointMaster" exact component={PointTransferredMaster} /> 
 




        <Route path="/userWithdrawRequest" exact component={WithdrawRequest} /> 
        <Route path="/PlayersHistrory" exact component={PlayerHistory} /> 
        <Route path="/GamePlayHistory" exact component={GameHistory} /> 
        <Route path="/AndarBaharGamePlayHistory" exact component={AndarBaharGameHistory} />
        <Route path="/RoulletGamePlayHistory" exact component={RoulletGameHistory} />
        <Route path="/FunTargetGamePlayHistory" exact component={FunTargetGameHistory} /> 
        <Route path="/TripleChanceGamePlayHistory" exact component={TripleChanceGameHistory} /> 
        <Route path="/SevenUpGamePlayHistory" exact component={SevenUpDownGameHistory} /> 




        <Route path="/Triplegame" exact component={Triplegame} />
        <Route path="/ShowCurrentBet" exact component={ShowCurrentBet} /> 
        <Route path="/JeetoJokerGame" exact component={RoulletGame} /> 
        <Route path="/16CardsGame" exact component={SevenUpDownGame} /> 
        <Route path="/SpinWinGame" exact component={FunTargetGame} /> 
        <Route path="/AndarbaharGame" exact component={AndarBaharGames} /> 

        







        <Route path="/ChangePassword" exact component={ChangePassword} /> 
        <Route path="/ResetUserPassword" exact component={ChangeUserPassword} /> 
        <Route path="/cards" exact component={Cards} /> 
        <Route path="/UsersList" exact component={UsersList} /> 


        <Route path="/childIds" exact component={ViewChildId} /> 
        <Route path="/check_commission" exact component={CheckCommission} />
        </div>
    </section>
  )
}
