// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body{
    background-color: rgb(238, 238, 252);
}

/* ......logo.......  */
.boxStyle{
    margin: 20px 0 0 0;
}
.boxStyle :hover{
    transform: scale(1.1); 
}
img{
    border-radius: 15px;
} 
/* ........loginBody.......  */
.paperStyle{
    padding: 20px;
    height: 55vh;
    width: 370px;
    margin: 20px auto 0 auto;
}

.signin1 label{
    font-size: 16px;
}

input{
 font-size: 16px;   
}

b4{
    font-size: 16px;
}

.signin1 input{
    font-size: 16px;
}

.card-title{
    max-width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/style/Login.css"],"names":[],"mappings":";AACA;IACI,oCAAoC;AACxC;;AAEA,uBAAuB;AACvB;IACI,kBAAkB;AACtB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB;AACA,8BAA8B;AAC9B;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;CACC,eAAe;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\nbody{\n    background-color: rgb(238, 238, 252);\n}\n\n/* ......logo.......  */\n.boxStyle{\n    margin: 20px 0 0 0;\n}\n.boxStyle :hover{\n    transform: scale(1.1); \n}\nimg{\n    border-radius: 15px;\n} \n/* ........loginBody.......  */\n.paperStyle{\n    padding: 20px;\n    height: 55vh;\n    width: 370px;\n    margin: 20px auto 0 auto;\n}\n\n.signin1 label{\n    font-size: 16px;\n}\n\ninput{\n font-size: 16px;   \n}\n\nb4{\n    font-size: 16px;\n}\n\n.signin1 input{\n    font-size: 16px;\n}\n\n.card-title{\n    max-width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
