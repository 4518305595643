import React, { useState, useEffect } from "react";
import MaterialTable from "material-table"; 
import "../../style/Contact.css";
import axios from "axios";
import Swal from 'sweetalert2';  

const MasterIdList = () =>{
  return(
    <div style = {{fontSize: '18px', fontWeight: 'bold'}}>
      Master Id List
    </div>
  );
}

function ViewMasterId() {
 // let apiBaseURL  = "https://royalluck.club:5000"
  let apiBaseURL  = "https://royalluck.club:5000"

  const [data, setData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFetchIds = () => {
    setError('');
    if (email.trim() === '') {
      setError('Email cannot be empty.');
      return;
    }

    axios.post(`${apiBaseURL}/user/getEmailsById`, { email })
      .then(response => {
        const { masteridUsers, users } = response.data.result;
        const masteridEmails = masteridUsers.map(user => ({
          email: user.email,
          password: user.password,
          point: user.point
        }));
        const userEmails = users.map(user => ({
          email: user.email,
          password: user.password,
          point: user.point
        }));
        setEmails([...masteridEmails, ...userEmails]);
      })
      .catch(error => {
        setEmails([]);
        setError('Error retrieving IDs. Please try again.');
      });
  };



  const ViewMasterFunction =(email)=>{
  
    axios.post(`${apiBaseURL}/user/getEmailsById`, { email })
    .then(response => {
      const { masteridUsers, users } = response.data.result;
      const tableRows = users.map(user => 
        `<tr><td>${user.email}</td><td>${user.password}</td></tr>`).join('')

        Swal.fire({
          icon: 'success',
          title: 'Child Master IDs and Passwords',
          html: `<div style="background-color: white; color: black;">
                  <table style="width:100%">
                    <tr>
                      <th>Email</th>
                      <th>Password</th>
                    </tr>
                    ${tableRows}
                  </table>
                </div>`,
          customClass: {
            title: 'swal-title',
            content: 'swal-text',
          },
          iconHtml: '<i class="fa-solid fa-user-friends"></i>',
        }).then(() => {
          window.location.reload();
        });


    })


    .catch(error => {
      setEmails([]);
      setError('Error retrieving IDs. Please try again.');
    });
  

     }
 

  //get Agents
  const getPlayers = async () => {
    await axios
      .get(`${apiBaseURL}/user/getMasterIdData`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          setData(response.data.data);
        }
      })
      .catch(function (error) {
        
      });
  };
 
  const columns = [
    { title: "Sl No.", field: "id", headerStyle: { fontSize: '16px' } },
    { title: "Master id", field: "email", headerStyle: { fontSize: '16px' } },
    { title: "Point", field: "point", headerStyle: { fontSize: '16px' } },
    { title: "SuperMasterName", field: "supermastername", headerStyle: { fontSize: '16px' } },
    { title: "Commission %", field: "commission", headerStyle: { fontSize: '16px' } },


    { title: "Password", field: "password", headerStyle: { fontSize: '16px' } },
    {
      title: "Actions", headerStyle: { fontSize: '18px' },
      render: (rowData) => (
        <>
          <button
            className="btn btn-danger ml-2"
            onClick={() =>
              handleEdit(rowData, apiBaseURL, getPlayers, rowData.full_name)
            }
          >
            Edit
          </button>
          <button
            className="btn btn-danger ml-2 mt-2"
            onClick={() => handleDelete(rowData.email, getPlayers)}
          >
            Delete
          </button>


          
        <button
        className="btn btn-primary my-2  mx-2"
        onClick={() => ViewMasterFunction(rowData.email,getPlayers)}
        >
          View Ids
        </button>
      
    
        </>
      ),
    },
  ];

  const handleDelete = (email, callback) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .delete(`${apiBaseURL}/user/deleteMasterId`, { data: { email } })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire("Deleted!", "MasterId has been deleted.", "success");
            callback();
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch(function (error) {
          console.error("Error deleting MasterId:", error);
          Swal.fire("Error", "Something went wrong", "error");
        });
    }
  });
};


  useEffect(() => {
    getPlayers();
  }, []);

  return ( 
    <div className="card card-outline card-info"  >
      <MaterialTable
        title={<MasterIdList />}
        data={data}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
 />
     {currentRow && (
        <EditForm
          row={currentRow}
          onClose={() => setCurrentRow(null)}
          apiBaseURL={apiBaseURL}
          getPlayers={getPlayers}
        />
      )}
    </div> 
  );
}

const handleEdit = async (rowData, apiBaseURL, getPlayers, firstName) => {
  const { id, password } = rowData;
  const newPassword = prompt(
    "Enter new password (leave blank to keep current password)"
  );
  const newFirstName = prompt("Enter new Commission");


  if (newFirstName !=null && newPassword != null) {
  if(parseInt(newFirstName)>=0&&parseInt(newFirstName)<=100 ){
   
  

  axios
    .put(`${apiBaseURL}/user/updateMasterId`, {
      id,
      full_name: newFirstName,
    //  Commission:Commission,
      password: newPassword || null,
    })
    .then(function (response) {
      if (response.status === 200) {
        Swal.fire("Success", response.data.message, "success").then(() =>
          getPlayers()
        );
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    })
    .catch(function (error) {
      Swal.fire("Error", "Something went wrong", "error");
    });
}
else{
  Swal.fire({
    title: 'Commission should be between 0 to 100',
    allowEscapeKey: false,
    allowOutsideClick: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

}
}else{
  alert('Please enter a valid password and commission');
}



};


function EditForm({ row, onClose, apiBaseURL, getPlayers }) {
  const [firstName, setFirstName] = useState(row.full_name);
  const [password, setPassword] = useState(row.password);

  const handleSave = async () => {
    try {
      const updatedData = {
        id: row.id,
        full_name: firstName,
        password: password,
      };
  
      await axios.put(`${apiBaseURL}/user//updateMasterId`, updatedData);
  
      onClose();
      getPlayers();
      alert("Masterid updated successfully");
    } catch (error) {
      console.error(error);
      alert("Failed to update Masterid");
    }
  };
  

  return (
    <div>
      <div>
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}

export default ViewMasterId;
