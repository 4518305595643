
let apiBaseURL = "https://royalluck.club:5000"

import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import "../../style/Contact.css";

//let apiBaseURL = "http://localhost:5000";

const CommissionDetailsList = () => {
  return (
    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
      Upcoming Commission Details
    </div>
  );
};

function UpcomingCommission() {
  const [tableData1, setTableData1] = useState([]);
  const [error1, setError1] = useState("");

  const handleFetchData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/user/SuperCommissionMaster`
      );

      if (response.data.status === 200 && response.data.data.length > 0) {
        setTableData1(response.data.data);
        setError1("");
      } else {
        setError1("No Record Found");
        setTableData1([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError1("Error fetching data");
    }
  };

  const getNextMonday = () => {
    const today = new Date();
    const day = today.getDay();
    const diff = 8 - day; // Calculate difference to get to next Monday
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + diff); // Add difference to get next Monday
    return nextMonday.toLocaleDateString("en-US");
  };

  const ViewMasterFunction = (email) => {
    axios
      .post(`${apiBaseURL}/user/MasterCommissionData`, { email })
      .then((response) => {
        const { data } = response.data;
        const tableRows = data
          .map((item) => {
            const { master, commission } = item;
            const commissionValue =
              commission[0].total_master_commission !== null
                ? commission[0].total_master_commission
                : 0;
            return `<tr><td>${master.email}</td><td>${commissionValue}</td></tr>`;
          })
          .join("");

        Swal.fire({
          icon: "success",
          title: "Master IDs and Commission",
          html: `<div style="background-color: white; color: blue;">
                  <table style="width:100%">
                    <tr>
                      <th>Email</th>
                      <th>Commission</th>
                    </tr>
                    ${tableRows}
                  </table>
                </div>`,
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
          iconHtml: '<i class="fa-solid fa-user-friends"></i>',
        });
      })
      .catch((error) => {
        setError1("Error retrieving IDs. Please try again.");
      });
  };

  const columns = [
    {
      title: "Serial No",
      render: (rowData) => rowData.tableData.id + 1,
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "SuperMasterName",
      field: "supermaster.email",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Commission Percentage",
      field: "supermaster.commission",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Point Played",
      field: "commission[0].points_played",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Total Commission of SuperMaster",
      field: "commission[0].total_supermaster_commission",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Total Commission of Master",
      field: "commission[0].total_master_commission",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "",
      headerStyle: { fontSize: "15px" },
      render: (rowData) => (
        <button
          className="btn btn-danger ml-2"
          onClick={() => ViewMasterFunction(rowData.supermaster.email)}
        >
          Commission of Master
        </button>
      ),
    },
  ];

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="card card-outline card-info">
      <div style={{ textAlign: "right", paddingRight: "20px" }}>
        <h3>Allocation Date: {getNextMonday()}</h3>
      </div>
      {error1 && <div>Error: {error1}</div>}
      <MaterialTable
        title={<CommissionDetailsList />}
        data={tableData1}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
      />
    </div>
  );
}

export default UpcomingCommission;
