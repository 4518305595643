// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarDiv{
    height: 100vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
}

/* .sideBar{
    height: 100%;
    width: 15vw;
    background-color: rgb(219, 15, 15);
} */

.side-bar li{
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/style/sideBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,oCAAoC;AACxC;;AAEA;;;;GAIG;;AAEH;IACI,eAAe;AACnB","sourcesContent":[".sideBarDiv{\n    height: 100vh;\n    width: 100vw;\n    background-color: rgb(255, 255, 255);\n}\n\n/* .sideBar{\n    height: 100%;\n    width: 15vw;\n    background-color: rgb(219, 15, 15);\n} */\n\n.side-bar li{\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
