import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import { apiBaseURL } from "../../config";
import MaterialTable from 'material-table'
import Swal from "sweetalert2";
import Navbar from "./Navbar";

function SevenUpDownGame() {

  const [data, setData] = useState([])
  const [value1, setvalue1] = useState("")
  const [value2, setvalue2] = useState("")
  const [min, setmin] = useState(0)
  const [max, setmax] = useState(0)
  const [minname, setminname] = useState("")
  const [maxname, setmaxname] = useState("")
  const [detail, setdetail] = useState([]);
  const [timerValue, setTimerValue] = useState(10);
  const [sevenupTimer, setsevenupTimer] = useState(null);

  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/sevendetail`)
      .then(function (response) {
        if (response.data.status === 200) {
          setdetail(response.data.data);
        }
      })
      .catch(function (error) {
      });
  };
  const getTimer = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getSevenUpDownTimerValue`);
      if (response.data.timerValue !== undefined) {
        setsevenupTimer(response.data.timerValue);
      }
    } catch (error) {
      console.error('Error fetching timer value:', error);
    }
  };

  useEffect(() => {

    getTimer();


    const interval = setInterval(() => {
      getTimer();
    }, 500);


    return () => clearInterval(interval);
  }, []);



  const columns = [
    { title: "Betvalue2to6", field: "two_six", headerStyle: { fontWeight: 'bold' } },
    { title: "Betvalue7", field: "seven", headerStyle: { fontWeight: 'bold' } },
    { title: "Betvalue8to12", field: "eight_twelve", headerStyle: { fontWeight: 'bold' } },
  ]
  const columns1 = [
    //  { title: "Serial No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Player ID", field: "playername", headerStyle: { fontWeight: 'bold' } },
    { title: "RoundCount", field: "RoundCount", headerStyle: { fontWeight: 'bold' } },
    { title: "2_6", field: "singleNo", headerStyle: { fontWeight: 'bold' } },
    { title: "7", field: "doubleNo", headerStyle: { fontWeight: 'bold' } },
    { title: "8_12", field: "tripleNo", headerStyle: { fontWeight: 'bold' } },
    { title: "Total Bet Placed", field: "TotalBet", headerStyle: { fontWeight: 'bold' } },


  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerValue((prevValue) => {
        if (prevValue === 0) {
          return 60;
        } else {
          return prevValue - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    axios

      // .get(`${apiBaseURL}/user/SevenUpGamePlayHistory`)
      .get(`${apiBaseURL}/user/gamerunning`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email != "admin@admin.com"

          })

          var temp = []
          setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("calling in everyone")
      axios
        .get(`${apiBaseURL}/user/gamerunning`)


        .then(function (response) {
          if (response.data.status === 200) {
            console.log(response.data, "data");
            const result = response.data.data.filter((item) => {
              return item.email != "admin@admin.com"

            })
            setData(result);
            console.log(result, "Result")
            setData(result);
            var obj = result[0]
            var name = []
            var arr = Object.keys(obj).map(function (key) {

              if (typeof obj[key] !== 'object' && obj[key] !== 'undefined') {
                name.push(key)
                return obj[key];
              }
              else {
                return null
              }
            });
            console.log(name, "namearr")
            var res = arr.filter(elements => {
              return elements !== null;
            });

            console.log(res, "res")
            var min = Math.min.apply(null, res);
            var max = Math.max.apply(null, res);
            setmin(min)
            setmax(max)
            setminname(name[res.indexOf(min)])
            setmaxname(name[res.indexOf(max)])


            // setData(response.data.data);
          }
        })
        .catch(function (error) {
          // history.push("/login")
        });
    }, 10000);
    gameReports()

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    axios
      // .get(`${apiBaseURL}/user/PointHistory`)
      .get(`${apiBaseURL}/user/getAdmin7up`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          setvalue1(response.data.data.value1)
          setvalue2(response.data.data.value2)

          // setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, [])







  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);


  //checkbox selection
  //desktop view
  const [dice1SelectedCheckbox, setDice1SelectedCheckbox] = useState("");
  const [dice2SelectedCheckbox, setDice2SelectedCheckbox] = useState("");
  const [resultSelectedCheckbox, setResultSelectedCheckbox] = useState("");


  const handleDice1CheckboxChange = (value) => {
    if (dice1SelectedCheckbox === value) {
      setDice1SelectedCheckbox("");
    } else {
      setDice1SelectedCheckbox(value);
    }
  };

  const handleDice2CheckboxChange = (value) => {
    if (dice2SelectedCheckbox === value) {
      setDice2SelectedCheckbox("");
    } else {
      setDice2SelectedCheckbox(value);
    }
  };


  //Mobile view
  const [dice1SelectedDropDown, setDice1SelectedDropDown] = useState("");
  const [dice2SelectedDropDown, setDice2SelectedDropDown] = useState("");
  const [resultSelectedDropDown, setResultSelectedDropDown] = useState("");


  const handleDice1DropDownChange = (event) => {
   
    const value = event.target.value;
    
    if (dice1SelectedDropDown === value) {
      setDice1SelectedDropDown("");
    } else {
      setDice1SelectedDropDown(value);
    }
  };

  const handleDice2DropDownChange = (event) => {
    
    const value = event.target.value;

    if (dice2SelectedDropDown === value) {
      setDice2SelectedDropDown("");
    } else {
      setDice2SelectedDropDown(value);
    }
  };


  //clear button
  const handleClearButtonClick = (event) => {
    event.preventDefault();
    setDice1SelectedCheckbox("");
    setDice2SelectedCheckbox("");
    setvalue1(-1)
    setvalue2(-1)

    axios.post("https://royalluck.club:5000/user/Admin7up", { value1: -1, value2: -1 })

      .then((data) => {
        console.log(data)

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Reset",
          showConfirmButton: false,
          timer: 1500,
        });

      })
      .catch((error) => {
        console.log(error)
      })
  };

  // Save end 
  //desktop view
  const handleSaveButtonClick = (event) => {
    event.preventDefault();
    setvalue1(dice1SelectedCheckbox);
    setvalue2(dice2SelectedCheckbox);

    axios.post("https://royalluck.club:5000/user/Admin7up", { value1: dice1SelectedCheckbox, value2: dice2SelectedCheckbox })

      .then((data) => {
        console.log(data)

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });

      })
      .catch((error) => {
        console.log(error)
      })
  }


  //Mobile view
  const handleSaveButtonClick1 = (event) => {
    event.preventDefault();
    setvalue1(dice1SelectedDropDown);
    setvalue2(dice2SelectedDropDown);

    axios.post("https://royalluck.club:5000/user/Admin7up", 
        { value1: dice1SelectedDropDown, 
            value2: dice2SelectedDropDown })

      .then((data) => {
        console.log(data)

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });

      })
      .catch((error) => {
        console.log(error)
      })
  }

  //checkbox selection end

  return (
    <div className="col-md-12">

      <div className="card card-outline card-info">

        <Navbar />


        <div className="card-body">
          <div className="row">
            {/* First GAme */}
            <div className="col-md-3">
              <div className="card card-outline card-warning">
                <div className="d-flex">
                  <div className="p-2">
                    <h3 className="card-title" style = {{fontSize: '14px'
                    }}>SevenUpDownGame</h3>

                    <br />
                    <hr />
                    
                    <table className = 'col-md-12'>
                      <tr>
                        <th className = ''>DiceT1</th>
                        <th >DiceT2</th>
                      </tr>
                      <tbody>
                        <tr>

                          <td >
                            {value1 == -1 ? "none" : value1}
                          </td>
                          <td >
                            {value2 == -1 ? "none" : value2}
                          </td>

                        </tr>
                      </tbody>
                    </table>



                  </div>
                </div>

                <form className="shadow-sm">
                  <div className="row">
                    <div className="col-md-4">
                      <button className="btn btn-primary mr-2" style={{ marginBottom: '10px' }} onClick={handleClearButtonClick}>
                        Clear
                      </button>
                    </div>

                    {/* desktop view */}
                    <div className="col-md-4">
                      <button className="btn btn-success  div2 b1"
                        style={{ marginLeft: '14px' }}
                        onClick={handleSaveButtonClick}>
                        Save
                      </button>
                    </div>

                    {/* Mobile view */}
                    <div className="col-md-4 ">
                      <button className="btn btn-success position-relative div2 b2"
                        style={{ marginLeft: '10px', width: '60px' }}
                        onClick={handleSaveButtonClick1}>
                        Save
                      </button>
                    </div>


                  </div>
                  <div className=" desktopView" >
                    <span className="col-sm-6">Dice1</span>
                    <span className="col-sm-6">Dice2</span>
                  </div>

                  {/* desktop view */}
                  <div className="row" style = {{marginTop: '0px'}}>
                    <div className="col-sm-6" style={{ marginLeft: '10px' }}>
                      <ul className="list-group custome-ul-class two-column-list">
                        <div className="desktopView">
                          <li className="list-group-items">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown1"
                                value="1"
                                checked={dice1SelectedCheckbox.includes("1")}
                                onChange={() => handleDice1CheckboxChange("1")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown1">
                                1
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown2"
                                value="2"
                                checked={dice1SelectedCheckbox.includes("2")}
                                onChange={() => handleDice1CheckboxChange("2")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown2">
                                2
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown3"
                                value="3"
                                checked={dice1SelectedCheckbox.includes("3")}
                                onChange={() => handleDice1CheckboxChange("3")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown3">
                                3
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown4"
                                value="4"
                                checked={dice1SelectedCheckbox.includes("4")}
                                onChange={() => handleDice1CheckboxChange("4")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown4">
                                4
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown5"
                                value="5"
                                checked={dice1SelectedCheckbox.includes("5")}
                                onChange={() => handleDice1CheckboxChange("5")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown5">
                                5
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown6"
                                value="6"
                                checked={dice1SelectedCheckbox.includes("6")}
                                onChange={() => handleDice1CheckboxChange("6")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown6">
                                6
                              </label>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>



                    <div className="">
                      <ul className="list-group custome-ul-class two-column-list">
                        <div className="desktopView">
                          <li className="list-group-items">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown6"
                                value="1"
                                checked={dice2SelectedCheckbox.includes("1")}
                                onChange={() => handleDice2CheckboxChange("1")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown6">
                                1
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown7"
                                value="2"
                                checked={dice2SelectedCheckbox.includes("2")}
                                onChange={() => handleDice2CheckboxChange("2")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown7">
                                2
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown8"
                                value="3"
                                checked={dice2SelectedCheckbox.includes("3")}
                                onChange={() => handleDice2CheckboxChange("3")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown8">
                                3
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown9"
                                value="4"
                                checked={dice2SelectedCheckbox.includes("4")}
                                onChange={() => handleDice2CheckboxChange("4")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown9">
                                4
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown10"
                                value="5"
                                checked={dice2SelectedCheckbox.includes("5")}
                                onChange={() => handleDice2CheckboxChange("5")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown10">
                                5
                              </label>
                            </div>
                          </li>

                          <li className="list-group-items">
                            <div className="form-check pt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="sevenUpDown"
                                id="sevenUpDown11"
                                value="6"
                                checked={dice2SelectedCheckbox.includes("6")}
                                onChange={() => handleDice2CheckboxChange("6")}
                              />
                              <label className="form-check-label" htmlFor="sevenUpDown11">
                                6
                              </label>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>

                    {/* Mobile view */}
                    <div className="mobileView">
                      <div className="col-md-4" style={{ marginLeft: '10px' }}>

                        <select value = { dice1SelectedDropDown }
                          onChange={ handleDice1DropDownChange }
                          style={{ width: '130px', marginLeft: '-12px', marginTop: '10px'}}>

                            <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown1"
                            value="">
                            Dice 1
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown1"
                            value="1">
                            1
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown2"
                            value="2">
                            2
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown3"
                            value="3">
                            3
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown4"
                            value="4">
                            4
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown5"
                            value="5">
                            5
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown6"
                            value="6">
                            6
                          </option>
                        </select>
                      </div>



                      <div className="col-md-4">

                        <select value = { dice2SelectedDropDown }
                          onChange={ handleDice2DropDownChange }
                          style={{ width: '130px', marginLeft: '-2px', marginTop: '10px' }}>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown6"
                            value="">
                            Dice 2
                          </option>


                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown6"
                            value="1">
                            1
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown7"
                            value="2">
                            2
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown8"
                            value="3">
                            3
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown9"
                            value="4">
                            4
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown10"
                            value="5">
                            5
                          </option>

                          <option
                            className="form-check-input"
                            name="sevenUpDown"
                            id="sevenUpDown11"
                            value="6">
                            6
                          </option>
                        </select>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* First End */}

            <div className="col-md-9">
              <h3>Timer: {sevenupTimer !== null ? sevenupTimer : "Loading..."}</h3>
              <h4>
                Lowest:{minname}<h5>{min}</h5>

              </h4>
              <h4>
                Highest:{maxname}<h5>{max}</h5>
              </h4>

              <MaterialTable
                title="Current Betting User List"
                data={data}
                columns={columns}
              />
              <MaterialTable
                title="Current Betting "
                data={detail}
                columns={columns1}
              />

            </div>

          </div>
        </div>
      </div>
    </div>

  );
}
export default SevenUpDownGame;
