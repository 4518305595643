import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import { apiBaseURL } from "../../config";
import MaterialTable from "material-table";

import Swal from "sweetalert2";
import Navbar from "./Navbar";



function WalletPercentage() {
 
  const [walletPercent, setWalletPercentValue] = useState();



  async function setWalletPercent() {
    try {
      let response = await axios.post(
        `${apiBaseURL}/walletPercent/setWalletPercent`, {
          walletPercent,
          status: 1
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Wallet Percent set successfully!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else if (response.status === 204) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Wallet Percent cleared successfully!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Not Found",
          text: "Requested data not found!",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong!",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });

      console.log("Something went wrong", error);
    }
  }


  async function setWalletPercent() {
    try {
      let response = await axios.post(
        `${apiBaseURL}/walletPercent/setWalletPercent`, {
          walletPercent,
          status: 1
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Wallet Percent set successfully!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else if (response.status === 204) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Wallet Percent cleared successfully!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Not Found",
          text: "Requested data not found!",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong!",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });

      console.log("Something went wrong", error);
    }
  }



  const clearWalletPercent = async () => {
    try {
      let response = await axios.post(`${apiBaseURL}/walletPercent/setWalletPercent`, {
        walletPercent: 0,
        status: 0
      });

      if (response.status === 200) {
        setWalletPercentValue(0);
        Swal.fire("Success", "Wallet Percent cleared successfully!", "success");
      } else {
        Swal.fire("Error", "Something went wrong!", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };


  async function getWalletPercent() {
    try {
      let response = await axios.get(`${apiBaseURL}/walletPercent/getWalletPercent`);
      let data = response.data.data;
      
      if (data && data.length > 0) {
        const walletData = data[0].walletPercent;
        setWalletPercentValue(walletData);
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  }
  

  useEffect(() => {
    getWalletPercent();
  }, [])
 
  return (

    <div className="col-md-12" >
    <div className="card card-outline card-info">
      <Navbar />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Pura screen cover kare
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "#008080",
          color: "#fff",
          padding: "20px",
          borderRadius: "10px",
          textAlign: "center",
          transition: "background-color 0.3s ease",
          minWidth: "300px",
        }}
        className="hover-effect"
      >
        <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>
          {walletPercent}
        </h2>
  
        <input
          type="number"
          value={walletPercent}
          min="0"
          max="100"
          placeholder="Enter Wallet Percent"
          className="form-control mb-2"
          style={{ maxWidth: "100%", margin: "0 auto" }}
          onChange={(e) => {
            let value = e.target.value.replace(/^0+/, "");
            if (parseInt(value) > 100) value = "100";
            setWalletPercentValue(value);
          }}
        />
  
        <p>Wallet Percent</p>
        <button className="btn btn-primary me-2" onClick={setWalletPercent}>
          Save
        </button>
        <button className="btn btn-warning" onClick={clearWalletPercent}>
          Clear
        </button>
      </div>
    </div>
    </div>
    </div>
  );
  
}
export default WalletPercentage;
