import React from 'react';
import { Link } from 'react-router-dom';
import "../../style/header.css";


const Navbar = () => {
  return (
    <nav className="col-lg-12 navbar navbar-expand-md navbar-dark pt-2 pb-2">
      <button
        className="navbar-toggler bg-gray"  
        style = {{marginLeft: '180px'}}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mynav2"
        aria-controls="mynav2"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="borders collapse navbar-collapse" id="mynav2">
        <ul className="nav nav-tabs navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link" to="/Triplegame">Triple Chance</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/AndarbaharGame">AndarbaharGame</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/JeetoJokerGame">Roullet</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/16CardsGame">Sevenup</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/SpinWinGame">FuntargetGame</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
