import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Box, Button, Typography } from "@material-ui/core";
import Swal from "sweetalert2";

export default function Home() {
  let apiBaseURL = "https://royalluck.club:5000";

  const [dateTime, setDateTime] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [superMasters, setSuperMasters] = useState([]);
  const [masterIdDataLength, setMasterIdDataLength] = useState(0);
  const [filteredPlayersCount, setFilteredPlayersCount] = useState(0);
  const [points, setPoints] = useState(0);
 // const [walletPercent, setWalletPercentValue] = useState();

  useEffect(() => {
    const timer = setInterval(() => {
      const current = new Date();
      setDateTime(
        `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      );
      setTodayDate(moment(current).format("dddd DD MMMM,YYYY"));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    try {
      const superMasterResponse = await axios.get(
        `${apiBaseURL}/user/getSuperMasterData`
      );
      if (superMasterResponse.data.status === 200) {
        setSuperMasters(superMasterResponse.data.data);
      }

      const adminLogin = sessionStorage.getItem("loginEmail");
      const playerPointResponse = await axios.post(
        `${apiBaseURL}/user/getPlayerPoint`,
        { email: adminLogin }
      );
      console.log(
        "this is playerPointResponse" + playerPointResponse.data.point
      );
      setPoints(playerPointResponse.data.point);
      const masterIdDataResponse = await axios.get(
        `${apiBaseURL}/user/getMasterIdData`
      );
      if (masterIdDataResponse.data.status === 200) {
        setMasterIdDataLength(masterIdDataResponse.data.data.length);
      }

      const playerAdminResponse = await axios.get(
        `${apiBaseURL}/user/getPlayerAdmin`
      );
      if (playerAdminResponse.data.status === 200) {
        const result = playerAdminResponse.data.data.filter(
          (item) => item.email !== "admin@admin.com"
        );
        setFilteredPlayersCount(result.length);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // async function setWalletPercent() {
  //   try {
  //     let response = await axios.post(
  //       `${apiBaseURL}/walletPercent/setWalletPercent`, {
  //         walletPercent,
  //         status: 1
  //       }
  //     );

  //     if (response.status === 200) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success",
  //         text: "Wallet Percent set successfully!",
  //         confirmButtonColor: "#3085d6",
  //         confirmButtonText: "OK",
  //       });
  //     } else if (response.status === 204) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success",
  //         text: "Wallet Percent cleared successfully!",
  //         confirmButtonColor: "#3085d6",
  //         confirmButtonText: "OK",
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Not Found",
  //         text: "Requested data not found!",
  //         confirmButtonColor: "#d33",
  //         confirmButtonText: "OK",
  //       });
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "Something went wrong!",
  //       confirmButtonColor: "#d33",
  //       confirmButtonText: "OK",
  //     });

  //     console.log("Something went wrong", error);
  //   }
  // }



  // const clearWalletPercent = async () => {
  //   try {
  //     let response = await axios.post(`${apiBaseURL}/walletPercent/setWalletPercent`, {
  //       walletPercent: 0,
  //       status: 0
  //     });

  //     if (response.status === 200) {
  //       setWalletPercentValue(0);
  //       Swal.fire("Success", "Wallet Percent cleared successfully!", "success");
  //     } else {
  //       Swal.fire("Error", "Something went wrong!", "error");
  //     }
  //   } catch (error) {
  //     Swal.fire("Error", "Something went wrong!", "error");
  //   }
  // };


  // async function getWalletPercent() {
  //   try {
  //     let response = await axios.get(`${apiBaseURL}/walletPercent/getWalletPercent`);
  //     let data = response.data.data;
      
  //     if (data && data.length > 0) {
  //       const walletData = data[0].walletPercent;
  //       setWalletPercentValue(walletData);
  //     }
  //   } catch (error) {
  //     console.log("Something went wrong", error);
  //   }
  // }
  

  // useEffect(() => {
  //   getWalletPercent();
  // }, [])

  return (
    <>
      <div
        style={{
          backgroundColor: "#f4f6f9",
          padding: "20px",
          borderBottom: "1px solid #ccc",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: "10px" }}>
            <h1
              style={{ color: "#333", fontSize: "24px", marginBottom: "10px" }}
            >
              {dateTime}
            </h1>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h1
              style={{ color: "#333", fontSize: "24px", marginBottom: "10px" }}
            >
              {todayDate}
            </h1>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        {/* Super Masters */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#17a2b8",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>
              {superMasters.length}
            </h2>
            <p>Super Masters</p>
          </div>
        </div>

        {/* Master Ids */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#dc3545",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>
              {masterIdDataLength}
            </h2>
            <p>Master Ids</p>
          </div>
        </div>

        {/* Players */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#28a745",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>
              {filteredPlayersCount}
            </h2>
            <p>Players</p>
          </div>
        </div>

        {/* Turn Over */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#ffc107",
              color: "#333",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{points}</h2>
            <p>Wallet</p>
          </div>
        </div>

        {/* <div className="col-12 col-sm-6 col-md-3 mt-3">
          <div
            style={{
              backgroundColor: "#008080",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>
              {walletPercent}
            </h2>

            {/* <input
              type="number"
              value={walletPercent}
              min="0"
              max="100"
              placeholder="Enter Wallet Percent"
              className="form-control mb-2"
              style={{ maxWidth: "100%", margin: "0 auto" }}
              onChange={(e) => {
                let value = parseInt(e.target.value, 10) || 0;
                if (value < 0) value = 0;
                if (value > 100) value = 100;
                setWalletPercentValue(value);
              }}
            /> */}

{/* <input
  type="number"
  value={walletPercent}
  min="0"
  max="100"
  placeholder="Enter Wallet Percent"
  className="form-control mb-2"
  style={{ maxWidth: "100%", margin: "0 auto" }}
  onChange={(e) => {
    let value = e.target.value.replace(/^0+/, "");
    
    if (parseInt(value) > 100) value = "100";
    setWalletPercentValue(value);
  }}
/>



            <p>Wallet Percent</p>
            <button
              className="btn btn-primary me-2"
              onClick={setWalletPercent}
            >
              Save
            </button>
            <button
              className="btn btn-warning"
              onClick={clearWalletPercent}
            >
              Clear
            </button>
          </div>
        </div> */}
      </div> 
    </>
  );
}
