import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";

function AddNewPlayer() {

  let apiBaseURL = "https://royalluck.club:5000";

  const sessionData = sessionStorage.getItem("token");
  const [values, setValues] = useState({
    full_name: "",
    password: "",
    email: "",
  });

  const [destriData, setDestriData] = useState("");
  const [iscreated, setiscreated] = useState(false);
  const [playerpassword, setPassword] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedSuperMaster, setSelectedSuperMaster] = useState('');
  const [superMasters, setSuperMasters] = useState([]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const { full_name, password } = values;
    const user = {
      password: playerpassword,
      full_name,
      email: destriData,
      role_id: 4,
      super_master_email: selectedSuperMaster

    };
    await axios({
      method: "post",
      url: `${apiBaseURL}/auth/adduserbyadmin`,
      data: user,
      headers: { Authorization: `Bearer ${sessionData.token}` },
    })
      .then(function (response) {
        setiscreated((pre) => !pre)
        if (response.data.status === 200) {


          setValues({
            full_name: "",
            password: "",
            email: "",



          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${response.data.message} !`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          if (response.data.status == 401) {
            sessionStorage.removeItem("token");
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };

  const getAgentPassword = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getPass`);
      if (response.data.statusCode === 200) {
        setPassword(response.data.password);
      } else {
        Swal.fire(`Error: ${response.data.message}`, "error");
      }
    } catch (error) {
      Swal.fire(`Something went wrong!`, "error");
    }
  };

  //get Agents
  const getAgents = async () => {
    await axios
      .get(`${apiBaseURL}/user/getPlayerId`)
      .then(function (response) {
        if (response.data.status === 200) {
          setDestriData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };
  //
  const getSupermaster = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getMasterIdData`);
      if (response.data.status === 200) {
        console.log(response.data, "data");
        setSuperMasters(response.data.data);
      }
    } catch (error) {

      console.error('Error fetching Super Masters: ', error);
    }
  };


  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  useEffect(() => {
    getSupermaster();
    getAgents();
    getAgentPassword();

  }, [iscreated]);

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-outline card-info">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fa-solid fa-user-tie fa-2x" /> Add New Player
            </h3>
          </div>
          <div className="card-body">
            <form method="post" onSubmit={handleSubmit}>


              <div className="form-group">
                <label htmlFor="masterid">Select  MasterID</label>
                <select
                  id="masterid"
                  name="masterid"
                  className="form-control"
                  value={selectedSuperMaster}
                  onChange={(e) => setSelectedSuperMaster(e.target.value)}
                  required
                >
                  <option value="" disabled>Select Master</option>
                  {superMasters.map((masterid) => (
                    <option key={masterid.email} value={masterid.email}>
                      {masterid.email}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group ">
                <label
                  htmlFor="staticEmail"
                //className="col-sm-3 col-form-label"
                >
                  Player Id
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    required
                    className="form-control"
                    disabled
                    value={destriData}

                  />


                </div>
              </div>


              <div className="form-group gp ">
                <label htmlFor="inputPassword"
                  className="col-sm-3 col-form-label"
                >
                  Enter Password
                </label>
                <div className="col-md-12 ml-0">
                  <div className="input-group">
                    <input
                      name="password"
                      value={playerpassword}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control password-input"
                      required
                    />

                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary ml-5"
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="form-group row">
                <div className="col-sm-6 mt-4">
                  <button className=" btn-primary form-control">
                    Reset
                  </button>
                </div>
                <div className="col-sm-6 ">
                  <button
                    type="onSubmit"
                    className="btn-success form-control mt-4"
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddNewPlayer;
