import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import '../../style/Contact.css'
import 'react-datepicker/dist/react-datepicker.css'
 
import axios from "axios";
// import { apiBaseURL } from "../../config";
import moment from 'moment'
let apiBaseURL  = "https://royalluck.club:5000"
// import { authToken } from "../../authToken";

const GamesPlayHistory = () =>{
  return(
    <div style = {{fontSize: '18px', fontWeight: 'bold'}}>Games Play Histroy</div>
  )
}



function SevenUpGameHistory() {
    const [data, setData] = useState([]);

    // get Agents
    const gameReports = async () => {
      await axios
        .get(`${apiBaseURL}/user/SevenUpGamePlayHistory`)
        .then(function (response) {
          if (response.data.status === 200) { 
            setData(response.data.data);
          }
        })
        .catch(function (error) { 
        });
    };
   
 
    //get Agents
   
    const columns = [
      { title: "Serial No", render: (rowData) => rowData.tableData.id + 1, headerStyle: { fontSize: '18px' } },
     //  { title: "Player ID", field: "playername" },
  
      { title: "RoundCount", field: "RoundCount", headerStyle: { fontSize: '18px' } },
       { title: "Dice1", field: "dice1", headerStyle: { fontSize: '18px' } },
      { title: "Dice2", field: "dice2", headerStyle: { fontSize: '18px' } },
  
      { title: "WinType", field: "wintype", headerStyle: { fontSize: '18px' } },
  
      //{ title: "Win_finalNo", field: "Win_finalNo" },
  
      {
        title: "Date & Time",field: "playedtime",  headerStyle: { fontSize: '18px' },

      },
    ];
  
    useEffect(() => {
      gameReports(); 
    }, []);
    return (
        <>
        <div  className="card card-outline card-info">
      <MaterialTable
        title={<GamesPlayHistory />}
        data={data}
        columns={columns}
        options={{ actionsColumnIndex: -1 }} 
      />
    </div>
        </>
    )
}
export default  SevenUpGameHistory;
