import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import "../../style/Contact.css";

function CheckCommission() {
  let apiBaseURL = "https://royalluck.club:5000"; 
  const [data, setData] = useState([]);

  const fetchUniqueEmailsWithShare = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getUniqueEmailsWithShare`);
      console.log("API Response:", response.data); 

      if (response.data && response.data.supermasters && response.data.masters) {
        const formattedData = [];

        response.data.supermasters.forEach((sm) => {
          if (sm.supermasterEmail) {
            formattedData.push({
              username: sm.supermasterEmail,
              commission: sm.totalSupermasterShare || "0.00", 
            });
          }
        });

        response.data.masters.forEach((m) => {
          if (m.masterEmail) {
            formattedData.push({
              username: m.masterEmail,
              commission: m.totalMasterShare || "0.00",
            });
          }
        });

        setData([...formattedData]); 
      }
    } catch (error) {
      console.error("Error fetching commission data:", error);
    }
  };

  useEffect(() => {
    fetchUniqueEmailsWithShare(); 
  }, []);

  const columns = [
    { title: "Username", field: "username", headerStyle: { fontSize: "18px" } },
    { title: "Commission", field: "commission", headerStyle: { fontSize: "18px" } },
  ];

  return (
    <>
      <div className="card card-outline card-info mt-5">
        <div className="card-header">
          <h3 className="card-title h4" style={{ fontSize: "16px" }}>Commission Details</h3>
        </div>
        <div className="card-body">
          <MaterialTable
            title="Commission Summary"
            data={data}
            columns={columns}
            options={{
              search: false,
              filtering: false,
              pageSize: 5, 
              headerStyle: { fontWeight: "bold" },
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CheckCommission;
