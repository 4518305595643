import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import { apiBaseURL } from "../../config";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import Navbar from "./Navbar";

// import { authToken } from "../../../authToken";
function AndarBaharGames() {
  const [data, setData] = useState([]);
  const [value1, setvalue1] = useState("")
  const [value2, setvalue2] = useState("")
  const [min, setmin] = useState(0)
  const [max, setmax] = useState(0)
  const [minname, setminname] = useState("")
  const [maxname, setmaxname] = useState("")
  const [detail, setdetail] = useState([]);
  const [andarbaharTimer, setandarbaharTimer] = useState(null);

  // get Agents
  // const gameReports = async () => {
  //   await axios
  //     .get(`${apiBaseURL}/user/andarbahardetail`)
  //     .then(function (response) {
  //       if (response.data.status === 200) { 
  //         setdetail(response.data.data);
  //       }
  //     })
  //     .catch(function (error) { 
  //     });
  // };
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/andarbahardetail`)
      .then(function (response) {
        if (response.data.status === 200) {
          setdetail(response.data.data);
        }
      })
      .catch(function (error) {
      });
  };
  const getTimer = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getAndarBaharTimerValue`);
      if (response.data.timerValue !== undefined) {
        setandarbaharTimer(response.data.timerValue);
      }
    } catch (error) {
      console.error('Error fetching timer value:', error);
    }
  };
  useEffect(() => {

    getTimer();


    const interval = setInterval(() => {
      getTimer();
    }, 500);


    return () => clearInterval(interval);
  }, []);


  const columns = [
    //{ title: "Serial No", render: rowData => rowData.tableData.id + 1 },
    // { title: "Player ID", field: "playername" },
    //{ title: "Round Count", field: "RoundCount" },

    //{ title: "CardResult", field: "Cardresult" },
    //{ title: "FinalResult", field: "finalresult" },
    { title: "Andhar", field: "Card_Andhar_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Bahar	", field: "Card_Bahar_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Heart", field: "Card_Heart_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Diamond", field: "Card_Diamond_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Club", field: "Card_Club_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Spade", field: "Card_Spade_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Red", field: "Card_Red_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Black", field: "Card_Black_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "A_6", field: "Card_A_6_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Seven", field: "Card_seven_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "8_K", field: "Card_8_K_amount", headerStyle: { fontWeight: 'bold' } },

    // { title: "Card_A_Amount", field: "Card_A_amount"},
    // { title: "Card_2_Amount", field: "Card_2_amount"},
    // { title: "Card_3_Amount", field: "Card_3_amount"},
    // { title: "Card_4_Amount", field: "Card_4_amount"},
    // { title: "Card_5_Amount", field: "Card_5_amount"},
    // { title: "Card_6_Amount", field: "Card_6_amount"},
    // { title: "Card_7_Amount", field: "Card_7_amount"},
    // { title: "Card_8_Amount", field: "Card_8_amount"},
    // { title: "Card_9_Amount", field: "Card_9_amount"},
    // { title: "Card_10_Amount", field: "Card_10_amount"},
    // { title: "Card_J_Amount", field: "Card_J_amount"},
    // { title: "Card_Q_Amount", field: "Card_Q_amount"},
    // { title: "Card_K_Amount", field: "Card_K_amount"},
    // { title: "BetOn0",field:"bet0" },
    // { title: "BetOn1",field:"bet1" },
    // { title: "BetOn2",field:"bet2" },
    // { title: "BetOn3",field:"bet3" },
    // { title: "BetOn4",field:"bet4" },
    // { title: "BetOn5",field:"bet5" },
    // { title: "BetOn6",field:"bet6" },
    // { title: "BetOn7",field:"bet7" },
    // { title: "BetOn8",field:"bet8" },
    // { title: "BetOn9",field:"bet9" },
    // { title: "BetOn10",field:"bet10" },
    // { title: "BetOn11",field:"bet11" },
    // { title: "BetOn12",field:"bet12" },
    // { title: "BetOn13",field:"bet13" },
    // { title: "BetOn14",field:"bet14" },
    // { title: "BetOn15",field:"bet15" },
    // { title: "BetOn16",field:"bet16" },

    // { title: "BetOn17",field:"bet17" },
    // { title: "BetOn18",field:"bet18" },
    // { title: "BetOn19",field:"bet19" },
    // { title: "BetOn20",field:"bet20" },
    // { title: "BetOn21",field:"bet21" },
    // { title: "BetOn22",field:"bet22" },
    // { title: "BetOn23",field:"bet23" },
    // { title: "BetOn24",field:"bet24" },
    // { title: "BetOn25",field:"bet25" },
    // { title: "BetOn26",field:"bet26" },
    // { title: "BetOn27",field:"bet27" },
    // { title: "BetOn28",field:"bet28" },
    // { title: "BetOn29",field:"bet29" },
    // { title: "BetOn30",field:"bet30" },
    // { title: "BetOn31",field:"bet31" },
    // { title: "BetOn32",field:"bet32" },
    // { title: "BetOn33",field:"bet33" },
    // { title: "BetOn34",field:"bet34" },
    // { title: "BetOn35",field:"bet35" },
    // { title: "BetOn36",field:"bet36" },

    // { title: "BetOn37",field:"bet37" },
    // { title: "BetOn38",field:"bet38" },
    // { title: "BetOn39",field:"bet39" },
    // { title: "BetOn40",field:"bet40" },

    // { title: "BetOn41",field:"bet41" },
    // { title: "BetOn42",field:"bet42" },
    // { title: "BetOn44",field:"bet44" },
    // { title: "BetOn44",field:"bet44" },
    // { title: "BetOn45",field:"bet45" },
    // { title: "BetOn46",field:"bet46" },

    // { title: "BetOn47",field:"bet47" },
    // { title: "BetOn48",field:"bet48" },
    // { title: "BetOn49",field:"bet49" },
    // { title: "BetOn50",field:"bet50" },
    // { title: "BetOn51",field:"bet51" },
    //  { title: "BetOn52",field:"bet52" },

    //   { title: "Date & Time", render: rowData => moment(rowData.playedtime).format("DD-MM-YYYY h:mm:ss ") }
  ];

  const columns1 = [
    { title: "Player ID", field: "playername", headerStyle: { fontWeight: 'bold' } },
    { title: "Round Count", field: "RoundCount", headerStyle: { fontWeight: 'bold' } },
    { title: "Andar Bet", field: "BetOnAndar", headerStyle: { fontWeight: 'bold' } },
    { title: "Bahar Bet", field: "BetOnBahar", headerStyle: { fontWeight: 'bold' } },
    { title: "Total Bet", field: "TotalBet", headerStyle: { fontWeight: 'bold' } },
  ];

  useEffect(() => {
    axios
      .get(`${apiBaseURL}/user/gamerunningandarbahar`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email != "admin@admin.com";
          });
          
          


          setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, []);


  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("calling in everyone")
      axios
        // .get(`${apiBaseURL}/user/PointHistory`)
        // .get(`${apiBaseURL}/user/TripleChanceGamePlayHistory`)
        .get(`${apiBaseURL}/user/gamerunningandarbahar`)


        .then(function (response) {
          if (response.data.status === 200) {
            console.log(response.data, "data");
            const result = response.data.data.filter((item) => {
              return item.email != "admin@admin.com"

            })
            setData(result);
            console.log(result, "Result")
            setData(result);
            var obj = result[0]
            var name = []
            var arr = Object.keys(obj).map(function (key) {

              if (typeof obj[key] !== 'object' && obj[key] !== 'undefined') {
                name.push(key)
                return obj[key];
              }
              else {
                return null
              }
            });
            console.log(name, "namearr")
            var res = arr.filter(elements => {
              return elements !== null;
            });

            console.log(res, "res")
            var min = Math.min.apply(null, res);
            var max = Math.max.apply(null, res);
            setmin(min)
            setmax(max)
            setminname(name[res.indexOf(min)])
            setmaxname(name[res.indexOf(max)])


            // setData(response.data.data);
          }
        })
        .catch(function (error) {
          // history.push("/login")
        });
    }, 10000);
    gameReports()

    return () => clearInterval(interval);
  }, []);









  useEffect(() => {
    axios
      // .get(`${apiBaseURL}/user/PointHistory`)
      .get(`${apiBaseURL}/user/getAdminandarbahar`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          // setvalue1(response.data.data.value1)
          //setvalue2(response.data.data.value2)
          //setvalue3(response.data.data.value3)

          //setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, [])

  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  //checkbox controller

  //desktop view
  const [symbolSelectedCheckbox, setSymbolSelectedCheckbox] = useState("");
  const [winNoSelectedCheckbox, setWinNoSelectedCheckbox] = useState("");

  const handleSymbolCheckboxChange = (value) => {
    if (symbolSelectedCheckbox === value) {
      setSymbolSelectedCheckbox("");
    } else {
      setSymbolSelectedCheckbox(value);

    }
  };

  const handleWinNoCheckboxChange = (value) => {
    if (winNoSelectedCheckbox === value) {
      setWinNoSelectedCheckbox("");
    } else {
      setWinNoSelectedCheckbox(value);
    }
  };


  //mobile view
  const [symbolSelectedDropDown, setSymbolSelectedDropDown] = useState("");
  const [winNoSelectedDropDown, setWinNoSelectedDropDown] = useState("");

  const handleSymbolDropDownChange = (event) => {

    const value = event.target.value;

    if (symbolSelectedDropDown === value) {
      setSymbolSelectedDropDown("");
    } else {
      setSymbolSelectedDropDown(value);

    }
  };

  const handleWinNoDropDownChange = (event) => {

    const value = event.target.value;

    if (winNoSelectedDropDown === value) {
      setWinNoSelectedDropDown("");
    } else {
      setWinNoSelectedDropDown(value);
    }
  };

  //clear button
  const handleClearButtonClick = (event) => {
    event.preventDefault();
    setSymbolSelectedCheckbox("");
    setWinNoSelectedCheckbox("");
    setvalue1(-1);
    setvalue2(-1);
    axios.post("https://royalluck.club:5000/user/Adminandarbahar", { 
      value1: -1 ,
      value2: -2
    }
    )

      .then((data) => {


        console.log(data);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Reset",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // clear button end
  const handleSaveButtonClick = (event) => {
    event.preventDefault();

    setvalue1(symbolSelectedCheckbox);
    setvalue2(setWinNoSelectedCheckbox);

    var value = 0;
    if (symbolSelectedCheckbox === 0) {
      if ((winNoSelectedCheckbox === 0)) {
        value = 39;
      }
      if ((winNoSelectedCheckbox === 1)) {
        value = 40;
      }
      if (winNoSelectedCheckbox === 2) {
        value = 41;
      }
      if (winNoSelectedCheckbox === 3) {
        value = 42;
      }
      if (winNoSelectedCheckbox === 4) {
        value = 43;
      }
      if (winNoSelectedCheckbox === 5) {
        value = 44;
      }
      if (winNoSelectedCheckbox === 6) {
        value = 45;
      }
      if (winNoSelectedCheckbox === 7) {
        value = 46;
      }
      if (winNoSelectedCheckbox === 8) {
        value = 47;
      }
      if (winNoSelectedCheckbox === 9) {
        value = 48;
      }
      if (winNoSelectedCheckbox === 10) {
        value = 49;
      }
      if (winNoSelectedCheckbox === 11) {
        value = 50;
      }
      if (winNoSelectedCheckbox === 12) {
        value = 51;
      }
    }

    if (symbolSelectedCheckbox === 1) {
      if ((winNoSelectedCheckbox === 0)) {
        value = 13;
      }
      if ((winNoSelectedCheckbox === 1)) {
        value = 14;
      }
      if (winNoSelectedCheckbox === 2) {
        value = 15;
      }
      if (winNoSelectedCheckbox === 3) {
        value = 16;
      }
      if (winNoSelectedCheckbox === 4) {
        value = 17;
      }

      if (winNoSelectedCheckbox === 5) {
        value = 18;
      }

      if (winNoSelectedCheckbox === 6) {
        value = 19;
      }
      if (winNoSelectedCheckbox === 7) {
        value = 20;
      }

      if (winNoSelectedCheckbox === 8) {
        value = 21;
      }

      if (winNoSelectedCheckbox === 9) {
        value = 22;
      }

      if (winNoSelectedCheckbox === 10) {
        value = 23;
      }

      if (winNoSelectedCheckbox === 11) {
        value = 24;
      }

      if (winNoSelectedCheckbox === 12) {
        value = 25;
      }
    }

    if (symbolSelectedCheckbox === 2) {
      if ((winNoSelectedCheckbox === 0)) {
        value = 0;
      }
      if ((winNoSelectedCheckbox === 1)) {
        value = 1;
      }
      if ((winNoSelectedCheckbox === 2)) {
        value = 2;
      }
      if (winNoSelectedCheckbox === 3) {
        value = 3;
      }
      if (winNoSelectedCheckbox === 4) {
        value = 4;
      }

      if (winNoSelectedCheckbox === 5) {
        value = 5;
      }

      if (winNoSelectedCheckbox === 6) {
        value = 6;
      }
      if (winNoSelectedCheckbox === 7) {
        value = 7;
      }

      if (winNoSelectedCheckbox === 8) {
        value = 8;
      }

      if (winNoSelectedCheckbox === 9) {
        value = 9;
      }

      if (winNoSelectedCheckbox === 10) {
        value = 10;
      }

      if (winNoSelectedCheckbox === 11) {
        value = 11;
      }

      if (winNoSelectedCheckbox === 12) {
        value = 12;
      }
    }


    if (symbolSelectedCheckbox === 3) {
      if ((winNoSelectedCheckbox === 0)) {
        value = 26;
      }
      if ((winNoSelectedCheckbox === 1)) {
        value = 27;
      }
      if ((winNoSelectedCheckbox === 2)) {
        value = 28;
      }
      if (winNoSelectedCheckbox === 3) {
        value = 29;
      }
      if (winNoSelectedCheckbox === 4) {
        value = 30;
      }

      if (winNoSelectedCheckbox === 5) {
        value = 31;
      }

      if (winNoSelectedCheckbox === 6) {
        value = 32;
      }
      if (winNoSelectedCheckbox === 7) {
        value = 33;
      }

      if (winNoSelectedCheckbox === 8) {
        value = 34;
      }

      if (winNoSelectedCheckbox === 9) {
        value = 35;
      }

      if (winNoSelectedCheckbox === 10) {
        value = 36;
      }

      if (winNoSelectedCheckbox === 11) {
        value = 37;
      }

      if (winNoSelectedCheckbox === 12) {
        value = 38;
      }
    }
    setvalue1(symbolSelectedCheckbox);
    setvalue2(winNoSelectedCheckbox);

    axios.post("https://royalluck.club:5000/user/Adminandarbahar", { value1: symbolSelectedCheckbox,

        value2: winNoSelectedCheckbox
     })
      .then((data) => {
        console.log(data);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //mobile view
  const handleSaveButtonClick1 = (event) => {
    event.preventDefault();
    setvalue1(symbolSelectedDropDown);
    setvalue2(winNoSelectedDropDown);
    var value = 0;
    if (symbolSelectedDropDown === 0) {
      if ((winNoSelectedDropDown === 0)) {
        value = 39;
      }
      if ((winNoSelectedDropDown === 1)) {
        value = 40;
      }
      if (winNoSelectedDropDown === 2) {
        value = 41;
      }
      if (winNoSelectedDropDown === 3) {
        value = 42;
      }
      if (winNoSelectedDropDown === 4) {
        value = 43;
      }
      if (winNoSelectedDropDown === 5) {
        value = 44;
      }
      if (winNoSelectedDropDown === 6) {
        value = 45;
      }
      if (winNoSelectedDropDown === 7) {
        value = 46;
      }
      if (winNoSelectedDropDown === 8) {
        value = 47;
      }
      if (winNoSelectedDropDown === 9) {
        value = 48;
      }
      if (winNoSelectedDropDown === 10) {
        value = 49;
      }
      if (winNoSelectedDropDown === 11) {
        value = 50;
      }
      if (winNoSelectedDropDown === 12) {
        value = 51;
      }
    }

    if (symbolSelectedDropDown === 1) {
      if ((winNoSelectedDropDown === 0)) {
        value = 13;
      }
      if ((winNoSelectedDropDown === 1)) {
        value = 14;
      }
      if (winNoSelectedDropDown === 2) {
        value = 15;
      }
      if (winNoSelectedDropDown === 3) {
        value = 16;
      }
      if (winNoSelectedDropDown === 4) {
        value = 17;
      }

      if (winNoSelectedDropDown === 5) {
        value = 18;
      }

      if (winNoSelectedDropDown === 6) {
        value = 19;
      }
      if (winNoSelectedDropDown === 7) {
        value = 20;
      }

      if (winNoSelectedDropDown === 8) {
        value = 21;
      }

      if (winNoSelectedDropDown === 9) {
        value = 22;
      }

      if (winNoSelectedDropDown === 10) {
        value = 23;
      }

      if (winNoSelectedDropDown === 11) {
        value = 24;
      }

      if (winNoSelectedDropDown === 12) {
        value = 25;
      }
    }

    if (symbolSelectedDropDown === 2) {
      if ((winNoSelectedDropDown === 0)) {
        value = 0;
      }
      if ((winNoSelectedDropDown === 1)) {
        value = 1;
      }
      if ((winNoSelectedDropDown === 2)) {
        value = 2;
      }
      if (winNoSelectedDropDown === 3) {
        value = 3;
      }
      if (winNoSelectedDropDown === 4) {
        value = 4;
      }

      if (winNoSelectedDropDown === 5) {
        value = 5;
      }

      if (winNoSelectedDropDown === 6) {
        value = 6;
      }
      if (winNoSelectedDropDown === 7) {
        value = 7;
      }

      if (winNoSelectedDropDown === 8) {
        value = 8;
      }

      if (winNoSelectedDropDown === 9) {
        value = 9;
      }

      if (winNoSelectedDropDown === 10) {
        value = 10;
      }

      if (winNoSelectedDropDown === 11) {
        value = 11;
      }

      if (winNoSelectedDropDown === 12) {
        value = 12;
      }
    }


    if (symbolSelectedDropDown === 3) {
      if ((winNoSelectedDropDown === 0)) {
        value = 26;
      }
      if ((winNoSelectedDropDown === 1)) {
        value = 27;
      }
      if ((winNoSelectedDropDown === 2)) {
        value = 28;
      }
      if (winNoSelectedDropDown === 3) {
        value = 29;
      }
      if (winNoSelectedDropDown === 4) {
        value = 30;
      }

      if (winNoSelectedDropDown === 5) {
        value = 31;
      }

      if (winNoSelectedDropDown === 6) {
        value = 32;
      }
      if (winNoSelectedDropDown === 7) {
        value = 33;
      }

      if (winNoSelectedDropDown === 8) {
        value = 34;
      }

      if (winNoSelectedDropDown === 9) {
        value = 35;
      }

      if (winNoSelectedDropDown === 10) {
        value = 36;
      }

      if (winNoSelectedDropDown === 11) {
        value = 37;
      }

      if (winNoSelectedDropDown === 12) {
        value = 38;
      }
    }
    setvalue1(symbolSelectedDropDown);
    setvalue2(winNoSelectedDropDown);

    axios.post("https://royalluck.club:5000/user/Adminandarbahar", { value1: symbolSelectedDropDown,
      value2: winNoSelectedDropDown
     })
      .then((data) => {
        console.log(data);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //checkbox end

  return (
    <div className="col-md-12">
      <div className="card card-outline card-info">
        <div className="borders">
          <Navbar />
        </div>

        <div className="card-body">
          <div className="row">
            {/* First Game */}
            <div className="col-md-3">
              <div className="card card-outline card-warning">
                <div className="d-flex">
                  <div className="p-2">
                    <h3 className="card-title" style = {{fontSize: '20px'}}>AndarBahar Game</h3>

                    <br />

                    <table className='mt-3'>
                      <tr>
                        <td className="col-md-6">Symbol</td>
                        <td className="col-md-6">Win</td>
                      </tr>


                      <tbody>
                          <tr>
                            <td className = 'col-md-6'>
                              {value1 == -1 ? "none" : value1}
                            </td>
                            <td className = 'col-md-6'>
                              {value2 == -1 ? "none" : value2}
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                </div>

                <form className="shadow-sm p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary mr-2 "
                        style={{ marginBottom: "10px" }}
                        onClick={handleClearButtonClick}
                      >
                        Clear
                      </button>
                    </div>

                    {/* desktop view */}
                    <div className="col-md-6 b1">
                      <button
                        className="btn btn-success position-relative div2 "
                        style={{ marginLeft: "2px", width: "60px" }}
                        onClick={handleSaveButtonClick}
                      >
                        Save
                      </button>
                    </div>

                    {/* mobile view */}
                    <div className="col-md-6 b2">
                      <button
                        className="btn btn-success position-relative div2 col-md-4"
                        style={{ marginLeft: "1px", width: "60px" }}
                        onClick={handleSaveButtonClick1}
                      >
                        Save
                      </button>
                    </div>
                  </div>

                  {/* desktop view */}
                  <div className="desktopView">
                    <p>Symbol</p>
                    <ul className="list-group custome-ul-class two-column-list">

                      <li className="list-group-items">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="symbol"
                            id="symbol0"
                            value="0"
                            checked={symbolSelectedCheckbox === "0"}
                            onChange={() => handleSymbolCheckboxChange("0")}
                          />
                          <label className="form-check-label" htmlFor="symbol0">
                            Spade
                          </label>
                        </div>
                      </li>
                      <li className="list-group-items">
                        <div className="form-check pt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="symbol"
                            id="symbol1"
                            value="1"
                            checked={symbolSelectedCheckbox === "1"}
                            onChange={() => handleSymbolCheckboxChange("1")}
                          />
                          <label className="form-check-label" htmlFor="symbol1">
                            Heart
                          </label>
                        </div>
                      </li>
                      <li className="list-group-items">
                        <div className="form-check pt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="symbol"
                            id="symbol2"
                            value="2"
                            checked={symbolSelectedCheckbox === "2"}
                            onChange={() => handleSymbolCheckboxChange("2")}
                          />
                          <label className="form-check-label" htmlFor="symbol2">
                            Diamond
                          </label>
                        </div>
                      </li>
                      <li className="list-group-items">
                        <div className="form-check pt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="symbol"
                            id="symbol3"
                            value="3"
                            checked={symbolSelectedCheckbox === "3"}
                            onChange={() => handleSymbolCheckboxChange("3")}
                          />
                          <label className="form-check-label pb-1" htmlFor="symbol3">
                            Club
                          </label>
                        </div>
                      </li>
                    </ul>

                    <p className="pt-3">Win no.</p>
                    <ul className="list-group custome-ul-class two-column-list">
                      <li className="list-group-items">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo4"
                            value="0"
                            checked={winNoSelectedCheckbox === "0"}
                            onChange={() => handleWinNoCheckboxChange("0")}
                          />
                          <label className="form-check-label" htmlFor="winNo4">
                            A
                          </label>
                        </div>
                      </li>


                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo5"
                            value="1"
                            checked={winNoSelectedCheckbox === "1"}
                            onChange={() => handleWinNoCheckboxChange("1")}
                          />
                          <label className="form-check-label" htmlFor="winNo5">
                            2
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo6"
                            value="2"
                            checked={winNoSelectedCheckbox === "2"}
                            onChange={() => handleWinNoCheckboxChange("2")}
                          />
                          <label className="form-check-label" htmlFor="winNo6">
                            3
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo7"
                            value="3"
                            checked={winNoSelectedCheckbox === "3"}
                            onChange={() => handleWinNoCheckboxChange("3")}
                          />
                          <label className="form-check-label" htmlFor="winNo7">
                            4
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo8"
                            value="4"
                            checked={winNoSelectedCheckbox === "4"}
                            onChange={() => handleWinNoCheckboxChange("4")}
                          />
                          <label className="form-check-label" htmlFor="winNo8">
                            5
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo9"
                            value="5"
                            checked={winNoSelectedCheckbox === "5"}
                            onChange={() => handleWinNoCheckboxChange("5")}
                          />
                          <label className="form-check-label" htmlFor="winNo9">
                            6
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo10"
                            value="6"
                            checked={winNoSelectedCheckbox === "6"}
                            onChange={() => handleWinNoCheckboxChange("6")}
                          />
                          <label className="form-check-label" htmlFor="winNo10">
                            7
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo11"
                            value="7"
                            checked={winNoSelectedCheckbox === "7"}
                            onChange={() => handleWinNoCheckboxChange("7")}
                          />
                          <label className="form-check-label" htmlFor="winNo11">
                            8
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo12"
                            value="8"
                            checked={winNoSelectedCheckbox === "8"}
                            onChange={() => handleWinNoCheckboxChange("8")}
                          />
                          <label className="form-check-label" htmlFor="winNo12">
                            9
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo13"
                            value="9"
                            checked={winNoSelectedCheckbox === "9"}
                            onChange={() => handleWinNoCheckboxChange("9")}
                          />
                          <label className="form-check-label" htmlFor="winNo13">
                            10
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo14"
                            value="10"
                            checked={winNoSelectedCheckbox === "10"}
                            onChange={() => handleWinNoCheckboxChange("10")}
                          />
                          <label className="form-check-label" htmlFor="winNo14">
                            J
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo15"
                            value="11"
                            checked={winNoSelectedCheckbox === "11"}
                            onChange={() => handleWinNoCheckboxChange("11")}
                          />
                          <label className="form-check-label" htmlFor="winNo15">
                            Q
                          </label>
                        </li>
                      </div>

                      <div className="form-check pt-2">
                        <li className="list-group-items">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="winNo"
                            id="winNo16"
                            value="12"
                            checked={winNoSelectedCheckbox === "12"}
                            onChange={() => handleWinNoCheckboxChange("12")}
                          />
                          <label className="form-check-label" htmlFor="winNo16">
                            K
                          </label>
                        </li>
                      </div>
                    </ul>
                  </div>


                  {/* mobile view */}
                  <div className="mobileView col-md-12 mt-5" style = {{marginLeft: '-15px'}}>
                    <select
                      style={{ width: "130px", marginLeft: "-2px", marginTop: '10px' }}
                      value={symbolSelectedDropDown}
                      onChange={handleSymbolDropDownChange}
                    >

                      <option className="form-check-input" name="symbol" id="symbol0" value="">
                        Symbol
                      </option>
                      <option className="form-check-input" name="symbol" id="symbol0" value="0">
                        Spade
                      </option>
                      <option className="form-check-input" name="symbol" id="symbol1" value="1">
                        Heart
                      </option>
                      <option className="form-check-input" name="symbol" id="symbol2" value="2">
                        Diamond
                      </option>
                      <option className="form-check-input" name="symbol" id="symbol3" value="3">
                        Club
                      </option>
                    </select>

                    <select
                      style={{ width: "130px", marginLeft: "-2px", marginTop: "10px" }}
                      value={winNoSelectedDropDown}
                      onChange={handleWinNoDropDownChange}
                    >
                      <option className="form-check-input" name="winNo" id="winNo4" value="">
                        Symbol number
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo4" value="0">
                        A
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo5" value="1">
                        2
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo6" value="2">
                        3
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo7" value="3">
                        4
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo8" value="4">
                        5
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo9" value="5">
                        6
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo10" value="6">
                        7
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo11" value="7">
                        8
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo12" value="8">
                        9
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo13" value="9">
                        10
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo14" value="10">
                        J
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo15" value="11">
                        Q
                      </option>
                      <option className="form-check-input" name="winNo" id="winNo16" value="12">
                        K
                      </option>
                    </select>
                  </div>
                </form>
              </div> 
            </div> 

            <div className="col-md-9">
              <h3>Timer: {andarbaharTimer !== null ? andarbaharTimer : "Loading..."}</h3>
              <h4>
                Lowest: {minname}
                <h5>{min}</h5>
              </h4>
              <h4>
                Highest: {maxname}
                <h5>{max}</h5>
              </h4>

              <MaterialTable
                title="Current Betting"
                data={data}
                columns={columns}
              />
              <MaterialTable
                title="Current Betting User List"
                data={detail}
                columns={columns1}
              />
            </div> 
          </div>
        </div> 
      </div> 
    </div>

  );
}
export default AndarBaharGames;
