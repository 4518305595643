import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";



function DebitPoints() {
  const [values, setValues] = useState({
    userId: "",
    amount: "",
  });



  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  let apiBaseURL = "https://royalluck.club:5000";
  const handleCancel = () => {
    // Swal.fire("Cancel", "Cancel button clicked", "success");
    setValues({ userId: "", amount: "" });
  };
  const handleDebit = async () => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/user/debitPoints`,
        {
          userId: values.userId,
          amount: values.amount,
        }
      );
      console.log(response.data); 
      Swal.fire("Debit", "Debit request sent successfully", "success");
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Failed or Insufficient balance");
    }
  }; 



  return (
    <div className="container mt-5">
      <div className="row justify-content-left">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header bg-primary text-white">
              <h3 className="card-title">Debit Points from Users</h3>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="userId">User ID</label>
                <input
                  type="text"
                  value={values.userId}
                  onChange={handleChange("userId")}
                  name="userId"
                  className="form-control"
                  placeholder="Enter User ID"
                />
              </div>
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  value={values.amount}
                  onChange={handleChange("amount")}
                  name="amount"
                  className="form-control"
                  placeholder="Enter Amount"
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="btn btn-danger btn-block"
                    onClick={handleDebit}
                  >
                    Debit
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebitPoints;
