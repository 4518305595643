import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
import { TextField, Button, makeStyles } from "@material-ui/core";
import "../../style/Contact.css";


function CheckCommission() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  // const columns = [
  //   { title: "Player ID", field: "player_id" },
  //   { title: "Commission", field: "points" },
  //   {
  //     title: "Updated At",
  //     render: (rowData) =>
  //       rowData.updated_at
  //         ? moment(rowData.updated_at).format("YYYY-M-D h:mm:ss")
  //         : "Not available",
  //     filtering: true,
  //   },
  // ];
  const sortedData = data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

const columns = [
  { title: "Player ID", field: "player_id", headerStyle: { fontSize: '18px' } },
  { title: "Commission", field: "points", headerStyle: { fontSize: '18px' } },
  {
    title: "Updated At", headerStyle: { fontSize: '18px' },
    render: (rowData) =>
      rowData.updated_at
        ? moment(rowData.updated_at).format("YYYY-MM-DD hh:mm:ss A")
        : "Not available",
    filtering: true,
  },
];

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/checkCommission`, {
        params: {
          fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
          toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
        },
      });
      if (response.data.status === 200) {
        setData(response.data.commissions);
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleWeeklySearch = async () => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/checkWeeklyCommission`, {
        fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
        toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
      });
      if (response.data.status === 200) {
        setData(response.data.commissions);
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleSearch(); // Initial search on component mount
  }, []);

  return (
    <>
      <div className="card card-outline card-info mt-5">
        <div className="card-header">
          <h3 className="card-title h4" style = {{fontSize: '16px'}}>Commission Details</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-3 h3">
              <TextField
                id="fromDate"
                label="From Date"
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-3 h3">
              <TextField
                id="toDate"
                label="To Date"
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-2 mt-5">
              <Button variant="contained" color="primary" onClick={handleSearch} style = {{fontSize: '10px'}}>
                All Records
              </Button>
            </div>
            <div className="col-md-2 mt-5">
              <Button variant="contained" color="primary" onClick={handleWeeklySearch} style = {{fontSize: '10px'}}>
                Search Weekly
              </Button>
            </div>
          </div>
          <MaterialTable title="Commission Detail" data={data} columns={columns} />
        </div>
      </div>
    </>
  );
}

export default CheckCommission;
