// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import moment from "moment";

// export default function Home() {
//   // let apiBaseURL = "https://royalluck.club:5000";
//   let apiBaseURL = "https://royalluck.club:5000";

//   let [dateTime, setDateTime] = useState();
//   let [todayDate, settodayDate] = useState();
//   let [superMasters, setSuperMasters] = useState([]);
//   let [masterIdDataLength, setMasterIdDataLength] = useState(0);
//   let [filteredPlayersCount, setFilteredPlayersCount] = useState(0);
  
//   const timer = setInterval(() => {
//     let current = new Date();
//     let date = moment(current).format("dddd DD MMMM,YYYY");
//     const times = `${current.getHours()}:${current.getMinutes() + 1
//       }:${current.getSeconds()}`;
//     setDateTime(times);
//     settodayDate(date);
//   }, 1000);

//   //get Agents
//   const getData = async () => {
   
//     //get supermaster
//     await axios
//       .get(`${apiBaseURL}/user/getSuperMasterData`)
//       .then(function (response) {
//         if (response.data.status === 200) {
//           setSuperMasters(response.data.data);
//         }
//       })
//       .catch(function (error) {

//       });


//     //get master id data 
//     await axios
//       .get(`${apiBaseURL}/user/getMasterIdData`)
//       .then(function (response) {
//         if (response.data.status === 200) {
//           setMasterIdDataLength(response.data.data.length);
//         }
//       })
//       .catch(function (error) {

//       });

//       //get player data
//       await axios
//       .get(`${apiBaseURL}/user/getPlayerAdmin`)
//       .then(function (response) {
//         if (response.data.status === 200) {
//           const result = response.data.data.filter((item) => {
//             return item.email !== "admin@admin.com";
//           });
//           setFilteredPlayersCount(result.length);
//         }
//       })
//       .catch(function (error) {
        
//       });

//   };

//   useEffect(() => {
//     getData();
//   }, []);
//   return (
//     <>
//      <div
//   style={{
//     backgroundColor: "#f4f6f9",
//     padding: "20px",
//     borderBottom: "1px solid #ccc",
//   }}
// >
//   <div style={{ display: "flex", justifyContent: "space-between" }}>
//     <div style={{ marginRight: "10px" }}>
//       <h1 style={{ color: "#333", fontSize: "24px", marginBottom: "10px" }}>
//         {dateTime}
//       </h1>
//     </div>
//     <div style={{ marginLeft: "10px" }}> 
//       <h1 style={{ color: "#333", fontSize: "24px", marginBottom: "10px" }}>
//         {todayDate}
//       </h1>
//     </div>
//   </div>
// </div>


//       <div className="row mt-4">
//         {/* Super Masters */}
//         <div className="col-12 col-sm-6 col-md-3">
//           <div
//             style={{
//               backgroundColor: "#17a2b8",
//               color: "#fff",
//               padding: "20px",
//               borderRadius: "10px",
//               textAlign: "center",
//               transition: "background-color 0.3s ease",
//             }}
//             className="hover-effect"
//           >
//             <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{superMasters.length}</h2>
//             <p>Super Masters</p>
//           </div>
//         </div>

//         {/* Master Ids */}
//         <div className="col-12 col-sm-6 col-md-3">
//           <div
//             style={{
//               backgroundColor: "#dc3545",
//               color: "#fff",
//               padding: "20px",
//               borderRadius: "10px",
//               textAlign: "center",
//               transition: "background-color 0.3s ease",
//             }}
//             className="hover-effect"
//           >
//             <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{masterIdDataLength}</h2>
//             <p>Master Ids</p>
//           </div>
//         </div>

//         {/* Players */}
//         <div className="col-12 col-sm-6 col-md-3">
//           <div
//             style={{
//               backgroundColor: "#28a745",
//               color: "#fff",
//               padding: "20px",
//               borderRadius: "10px",
//               textAlign: "center",
//               transition: "background-color 0.3s ease",
//             }}
//             className="hover-effect"
//           >
//             <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{filteredPlayersCount}</h2>
//             <p>Players</p>
//           </div>
//         </div>

//         {/* Turn Over */}
//         <div className="col-12 col-sm-6 col-md-3">
//           <div
//             style={{
//               backgroundColor: "#ffc107",
//               color: "#333",
//               padding: "20px",
//               borderRadius: "10px",
//               textAlign: "center",
//               transition: "background-color 0.3s ease",
//             }}
//             className="hover-effect"
//           >
//             <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>2,000</h2>
//             <p>Turn Over</p>
//           </div>
//         </div>
//         </div>
//     </>
//   );
// };
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

export default function Home() {
  let apiBaseURL = "https://royalluck.club:5000";

  const [dateTime, setDateTime] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [superMasters, setSuperMasters] = useState([]);
  const [masterIdDataLength, setMasterIdDataLength] = useState(0);
  const [filteredPlayersCount, setFilteredPlayersCount] = useState(0);
  const [points, setPoints] = useState(0); // Initializing with 0 for better number handling
 // const adminLogin = sessionStorage.getItem("loginEmail");

  useEffect(() => {
    const timer = setInterval(() => {
      const current = new Date();
      setDateTime(`${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`);
      setTodayDate(moment(current).format("dddd DD MMMM,YYYY"));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    try {
      const superMasterResponse = await axios.get(`${apiBaseURL}/user/getSuperMasterData`);
      if (superMasterResponse.data.status === 200) {
        setSuperMasters(superMasterResponse.data.data);
      }

      const adminLogin = sessionStorage.getItem("loginEmail");
      const playerPointResponse = await axios.post(`${apiBaseURL}/user/getPlayerPoint`, { email: adminLogin });
      console.log("this is playerPointResponse" + playerPointResponse.data.point);
      setPoints(playerPointResponse.data.point); 
      // if (playerPointResponse.data.status === 200) {
    
      //   setPoints(playerPointResponse.datapoint); 
      // }
  

      const masterIdDataResponse = await axios.get(`${apiBaseURL}/user/getMasterIdData`);
      if (masterIdDataResponse.data.status === 200) {
        setMasterIdDataLength(masterIdDataResponse.data.data.length);
      }

      const playerAdminResponse = await axios.get(`${apiBaseURL}/user/getPlayerAdmin`);
      if (playerAdminResponse.data.status === 200) {
        const result = playerAdminResponse.data.data.filter(item => item.email !== "admin@admin.com");
        setFilteredPlayersCount(result.length);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#f4f6f9",
          padding: "20px",
          borderBottom: "1px solid #ccc",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: "10px" }}>
            <h1 style={{ color: "#333", fontSize: "24px", marginBottom: "10px" }}>
              {dateTime}
            </h1>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h1 style={{ color: "#333", fontSize: "24px", marginBottom: "10px" }}>
              {todayDate}
            </h1>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        {/* Super Masters */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#17a2b8",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{superMasters.length}</h2>
            <p>Super Masters</p>
          </div>
        </div>

        {/* Master Ids */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#dc3545",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{masterIdDataLength}</h2>
            <p>Master Ids</p>
          </div>
        </div>

        {/* Players */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#28a745",
              color: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{filteredPlayersCount}</h2>
            <p>Players</p>
          </div>
        </div>

        {/* Turn Over */}
        <div className="col-12 col-sm-6 col-md-3">
          <div
            style={{
              backgroundColor: "#ffc107",
              color: "#333",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              transition: "background-color 0.3s ease",
            }}
            className="hover-effect"
          >
            <h2 style={{ fontSize: "36px", marginBottom: "10px" }}>{points}</h2>
            <p>Wallet</p>
          </div>
        </div>
      </div>
    </>
  );
}
