import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";

function AddSuperMaster() {
  let apiBaseURL = "https://royalluck.club:5000";

  var t = new Date();
  var SU = "SU" + t.getTime();

  const sessionData = sessionStorage.getItem("token");
  const [values, setValues] = useState({
    full_name: "",
    password: "",
    email: "",
    commission: "",
  });

  const [destriData, setDestriData] = useState([]);
  const [superpassword, setPassword] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const [commission, setcommission] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { full_name } = values;
    const user = {
      password: superpassword,
      full_name,
      email: destriData,
      role_id: 2,
      commission,
    };
    if (parseInt(commission) >= 0 && parseInt(commission) <= 100) {
      try {
        Swal.fire({
          title: "Please wait... we are creating 20 master id for super master",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
 
        const response = await axios.post(
          `${apiBaseURL}/auth/adduserbyadmin`,
          user,
          {
            headers: { Authorization: `Bearer ${sessionData.token}` },
          }
        );

        Swal.close();

        if (response.data.status === 200) {
          const childMasterIdResponse = await axios.post(
            `${apiBaseURL}/auth/getChildMasterid`,
            { email: destriData },
            {
              headers: { Authorization: `Bearer ${sessionData.token}` },
            }
          );

          if (childMasterIdResponse.data.status === 200) {
            const masterData = childMasterIdResponse.data.data;
            const tableRows = masterData
              .map(
                (master) =>
                  `<tr><td>${master.email}</td><td>${master.password}</td></tr>`
              )
              .join("");

            Swal.fire({
              icon: "success",
              title: "Child Master IDs and Passwords",
              html: `<div style="background-color: white; color: black;">
        <table style="width:100%; table-layout: fixed;">
          <tr>
            <th class="" style="width:50%; padding-left: 40px;">Email</th>
            <th class="" style="width:50%; padding-left: 30px;">Password</th>
          </tr>
          ${tableRows}
        </table>
      </div>`,
              customClass: {
                title: "swal-title",
                content: "swal-text",
              },
              iconHtml: '<i class="fa-solid fa-user-friends"></i>',
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to retrieve child master IDs and passwords.",
              customClass: {
                title: "swal-title",
                content: "swal-text",
              },
              iconHtml: '<i class="fa-solid fa-user-times"></i>',
            });
          }

          setValues({
            full_name: "",
            password: "",
            email: "",
            commission: "",
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Oops...",
            text: `${response.data.message} !`,
            showConfirmButton: false,
            timer: 1700,
          });
        }
      } catch (error) {
        console.error("API Error:", error);
        Swal.fire("Something went wrong!", "error");
      }
    } else {
      Swal.fire({
        title: "Commission should be between 0 to 100",
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  //get Agents
  const getAgents = async () => {
    await axios
      .get(`${apiBaseURL}/user/getPlayerId2`)
      .then(function (response) {
        if (response.data.status === 200) {
          setDestriData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };

  const getAgentPassword = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getPass`);
      if (response.data.statusCode === 200) {
        setPassword(response.data.password);
      } else {
        Swal.fire(`Error: ${response.data.message}`, "error");
      }
    } catch (error) {
      Swal.fire(`Something went wrong!`, "error");
    }
  };
  //

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  useEffect(() => {
    getAgents();
    getAgentPassword();
  }, []);

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-outline card-info">
          <div className="card-header bg-info text-white">
            <h3 className="card-title" style={{ fontSize: "14px" }}>
              <i className="fa-solid fa-user-tie mr-2" /> Add New Super Master
            </h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="superMasterId">Super Master ID</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={destriData}
                  readOnly
                  style={{
                    cursor: "not-allowed",
                  }}
                />
              </div>

              <div className="form-container">
                <label> Add Commission</label>
                <input
                  type="text"
                  className="form-control"
                  //  defaultValue={destriData}
                  //value={values.commission}
                  onChange={(event) => setcommission(event.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputPassword">Enter Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={superpassword}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button className="btn btn-secondary btn-block" type="reset">
                    Reset
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-success btn-block" type="submit">
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddSuperMaster;
