import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import MaterialTable from "material-table";

import Swal from "sweetalert2";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import "../../style/Login.css";
import '../../style/singup.css';
import Navbar from "./Navbar";
import {useHistory} from 'react-router-dom';


function ShowCurrentBet() {
  let apiBaseURL = "https://royalluck.club:5000";

  const [data, setData] = useState([]);
  const [value1, setvalue1] = useState("");
  const [value2, setvalue2] = useState("");
  const [value3, setvalue3] = useState("");
  const [islock, setislock] = useState(true);
  const [email, setemail] = useState("");
  const [min, setmin] = useState(0)
  const [max, setmax] = useState(0)
  const [minname, setminname] = useState("")
  const [maxname, setmaxname] = useState("")


  const [password, setpassword] = useState("");

  const [index, setindex] = useState([""]);

  const handleAdd = (i) => {
    setindex([...index, i]);
  };

  const handleemail = (e) => {
    setemail(e.target.value);
  };

  const handlepassword = (e) => {
    setpassword(e.target.value);
  };

  const navigateTripleChanceGames = useHistory();

  const onhandle = (e) => {
    e.preventDefault();
    if (email == "royalluck@admin.com" && password == "ab123") {
      navigateTripleChanceGames.push('/Triplegame')
      setislock(false);
      console.log('Game controller sign in hit');
    } else {
      setislock(true);
    }
  };

  const columns = [
   
    { title: "SingleBet", field: "singleNo", headerStyle: { fontWeight: 'bold' } },
    { title: "DoubleBet", field: "doubleNo", headerStyle: { fontWeight: 'bold' } },
    { title: "TripleBet", field: "tripleNo", headerStyle: { fontWeight: 'bold' } },

  ];

  
  useEffect(() => {
    axios.get(`${apiBaseURL}/user/gamerunningtriplechance`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "response data");
          const result = response.data.data.filter((item) => item.email !== "admin@admin.com");
          console.log("result", result);

          const temp = [];
          if (result.length >= 2) {
            temp.push(result[0]);
            temp.push(result[1]);
            temp.push(result[result.length - 1]);
            temp.push(result[result.length - 2]);
          }
          console.log("temp", temp);

          setData(temp);
        }
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
        // Handle error or redirect to login
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("calling in everyone")
      axios
        // .get(`${apiBaseURL}/user/PointHistory`)
        // .get(`${apiBaseURL}/user/TripleChanceGamePlayHistory`)
        .get(`${apiBaseURL}/user/gamerunningtriplechance`)


        .then(function (response) {
          if (response.data.status === 200) {
            console.log(response.data, "data");
            const result = response.data.data.filter((item) => {
              return item.email != "admin@admin.com"

            })
            setData(result);
            console.log(result, "Result")
            setData(result);
            var obj = result[0]
            var name = []
            var arr = Object.keys(obj).map(function (key) {

              if (typeof obj[key] !== 'object' && obj[key] !== 'undefined') {
                name.push(key)
                return obj[key];
              }
              else {
                return null
              }
            });
            console.log(name, "namearr")
            var res = arr.filter(elements => {
              return elements !== null;
            });

            console.log(res, "res")
            var min = Math.min.apply(null, res);
            var max = Math.max.apply(null, res);
            setmin(min)
            setmax(max)
            setminname(name[res.indexOf(min)])
            setmaxname(name[res.indexOf(max)])


            // setData(response.data.data);
          }
        })
        .catch(function (error) {
          // history.push("/login")
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    axios
      // .get(`${apiBaseURL}/user/PointHistory`)
      .get(`${apiBaseURL}/user/getAdmintriplechance`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          setvalue1(response.data.data.value1);
          setvalue2(response.data.data.value2);
          setvalue3(response.data.data.value3);

          // setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, []);

  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  //checkbox section
  const [innerWinSelectedCheckbox, setInnerWinSelectedCheckbox] = useState("");
  const [middleWinSelectedCheckbox, setMiddleWinSelectedCheckbox] =
    useState("");
  const [outerWinSelectedCheckbox, setOuterWinSelectedCheckbox] = useState("");

  // MobileView State
  const [innerWinDropDownMenu, setInnerWinDropDownMenu] = useState("");
  const [middleWinDropDownMenu, setMiddleWinDropDownMenu] = useState("");
  const [outerWinDropDownMenu, setOuterWinDropDownMenu] = useState("");

  //desktop view
  const handleInnerWinCheckboxChange = (value) => {
    if (innerWinSelectedCheckbox === value) {
      setInnerWinSelectedCheckbox("");
    } else {
      setInnerWinSelectedCheckbox(value);
    }
  };

  //mobile view event handler
  const handleInnerWinCheckboxChange1 = (event) => {
    const value = event.target.value;
    if (innerWinDropDownMenu === value) {
      setInnerWinDropDownMenu("");
    } else {
      setInnerWinDropDownMenu(value);
    }
  };

  //desktop view
  const handleMiddleWinCheckboxChange = (value) => {
    if (middleWinSelectedCheckbox === value) {
      setMiddleWinSelectedCheckbox("");
    } else {
      setMiddleWinSelectedCheckbox(value);
    }
  };

  //mobile view
  const handleMiddleWinDropDownMenu = (event) => {
    const value = event.target.value;
    if (middleWinDropDownMenu === value) {
      setMiddleWinDropDownMenu("");
    } else {
      setMiddleWinDropDownMenu(value);
    }
  };

  //desktop view
  const handleOuterWinCheckboxChange = (value) => {
    if (outerWinSelectedCheckbox === value) {
      setOuterWinSelectedCheckbox("");
    } else {
      setOuterWinSelectedCheckbox(value);
    }
  };

  const handleOuterWinDropDownMenu = (event) => {
    const value = event.target.value;
    if (outerWinDropDownMenu === value) {
      setOuterWinDropDownMenu("");
    } else {
      setOuterWinDropDownMenu(value);
    }
  };

  //clear button
  const handleClearButtonClick = (event) => {
    event.preventDefault();

    setInnerWinSelectedCheckbox("");
    setMiddleWinSelectedCheckbox("");
    setOuterWinSelectedCheckbox("");
    setvalue1(-1);
    setvalue2(-1);
    setvalue3(-1);
    axios
      .post("https://royalluck.club:5000/user/Admintriplechance", {
        value1: -1,
        value2: -1,
        value3: -1,
      })

      .then((data) => {
        console.log(data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Reset",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // clear button end
  // chebox section end

  //for mobile view
  const handleSaveButtonClick1 = (event) => {
    event.preventDefault();
    setvalue1(innerWinDropDownMenu);
    setvalue2(middleWinDropDownMenu);
    setvalue3(outerWinDropDownMenu);

    axios
      .post("https://royalluck.club:5000/user/Admintriplechance", {
        value1: innerWinDropDownMenu,
        value2: middleWinDropDownMenu,
        value3: outerWinDropDownMenu,
      })

      .then((data) => {
        console.log(data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveButtonClick = (event) => {
    event.preventDefault();
    setvalue1(innerWinSelectedCheckbox);
    setvalue2(middleWinSelectedCheckbox);
    setvalue3(outerWinSelectedCheckbox);

    axios
      .post("https://royalluck.club:5000/user/Admintriplechance", {
        value1: innerWinSelectedCheckbox,
        value2: middleWinSelectedCheckbox,
        value3: outerWinSelectedCheckbox,
      })

      .then((data) => {
        console.log(data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      {islock == true ? (
      <div className="container-fluid pt-2 contact-form-col f1">
      <form onSubmit={onhandle} className="f1">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <Paper elevation={15} className="paperStyle p-3">
              <Grid align="center" className="gridContainer pt-2">
                <h3 className="head1"><b>Sign Into Start Controller</b></h3>
              </Grid>

              <div className="txt-controller mb-3">
                <TextField
                  className="w-100"
                  id="outlined-basic1"
                  label="Email or Username"
                  value={email}
                  onChange={handleemail}
                  name="email"
                  type="text"
                  placeholder="Email or UserId"
                  variant="standard"
                />
              </div>
              <div className="txt-controller mb-3">
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  label="Password"
                  name="password"
                  value={password}
                  onChange={handlepassword}
                  type="password"
                  variant="standard"
                  InputProps={{ style: { fontSize: '16 px'} }}
                />
              </div>

              <div className="gridContainer mt-3 d-flex justify-content-center">
                <Button
                  type="submit"
                  className="mt-4"
                  onClick = {onhandle}
                  style={{ fontSize: '14px', width: '325px', marginLeft: '-7px' }}
                  variant="contained"
                  color="primary"
                >
                  Sign In
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
      ) : (
        <>

          <div className="col-md-12 container div1 col-md-12">
            <div className="card card-outline card-info ">

              <Navbar />
              <div className="card-body">
                <div className="row">
                  {/* First GAme */}
                  <div className="col-md-3">
                    <div className="card card-outline card-warning">
                      <div className="d-flex">
                        <div className="p-2">
                          <h3 className="card-title" style = {{fontSize: '20px'}}>TripleChanceGame</h3>
                          <br />
                          <hr />
                          <table>
                            <tr style={{ fontSize: '15px' }}>
                              <th style={{ marginRight: "50px" }}>
                                Single &nbsp;&nbsp;
                              </th>
                              <th style={{ marginRight: "20px" }}>
                                Double &nbsp;&nbsp;
                              </th>
                              <th>Triple</th>
                            </tr>
                            <tbody>
                              <tr>
                                <td style={{ marginRight: "20px" }}>
                                  {value1 == -1 ? "none" : value1}
                                </td>
                                <td style={{ marginRight: "20px" }}>
                                  {value2 == -1 ? "none" : value2}
                                </td>
                                <td>{value3 == -1 ? "none" : value3}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <form className="shadow-sm p-3">
                        <div className="row">
                          <div className="col-md-4">
                            <button
                              className="btn btn-primary mr-2"
                              style={{ marginBottom: "10px" }}
                              onClick={handleClearButtonClick}
                            >
                              Clear
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className="btn btn-success position-relative save b1" style={{ marginLeft: '5px' }}

                              onClick={handleSaveButtonClick}
                            >
                              Save
                            </button>
                          </div>

                          <div className="col-md-6">
                            <button
                              className="btn btn-success position-relative save b2" style={{ marginLeft: '5px' }}

                              onClick={handleSaveButtonClick1}
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        {/* Desktop View */}

                        <ul className="list-group custome-ul-class two-column-list desktopView">
                          <div className="desktopView">

                            <div className="row">
                              <div className="col-md-4 mt-1">

                                <b style={{ fontSize: '15px' }}>
                                  Single Win
                                </b>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin0"
                                      value="0"
                                      checked={innerWinSelectedCheckbox === "0"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("0")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin0"
                                    >
                                      
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin1"
                                      value="1"
                                      checked={innerWinSelectedCheckbox === "1"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("1")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin1"
                                    >
                                      1
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin2"
                                      value="2"
                                      checked={innerWinSelectedCheckbox === "2"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("2")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin2"
                                    >
                                      2
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin3"
                                      value="3"
                                      checked={innerWinSelectedCheckbox === "3"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("3")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin3"
                                    >
                                      3
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin4"
                                      value="4"
                                      checked={innerWinSelectedCheckbox === "4"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("4")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin4"
                                    >
                                      4
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin5"
                                      value="5"
                                      checked={innerWinSelectedCheckbox === "5"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("5")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin5"
                                    >
                                      5
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin6"
                                      value="6"
                                      checked={innerWinSelectedCheckbox === "6"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("6")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin6"
                                    >
                                      6
                                    </label>
                                  </div>
                                </li>


                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin7"
                                      value="7"
                                      checked={innerWinSelectedCheckbox === "7"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("7")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin7"
                                    >
                                      7
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin8"
                                      value="8"
                                      checked={innerWinSelectedCheckbox === "8"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("8")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin8"
                                    >
                                      8
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="innerWin"
                                      id="innerWin9"
                                      value="9"
                                      checked={innerWinSelectedCheckbox === "9"}
                                      onChange={() =>
                                        handleInnerWinCheckboxChange("9")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="innerWin9"
                                    >
                                      9
                                    </label>
                                  </div>
                                </li>
                              </div>


                              <div className="col-md-4 mt-1">

                                <b style={{ fontSize: '15px' }}>
                                  Double Win
                                </b>

                                {/* Checkbox for value 0 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin10"
                                      value="0"
                                      checked={middleWinSelectedCheckbox === "0"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("0")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin10"
                                    >
                                      0
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 1 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin11"
                                      value="1"
                                      checked={middleWinSelectedCheckbox === "1"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("1")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin11"
                                    >
                                      1
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 2 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin12"
                                      value="2"
                                      checked={middleWinSelectedCheckbox === "2"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("2")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin12"
                                    >
                                      2
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 3 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin13"
                                      value="3"
                                      checked={middleWinSelectedCheckbox === "3"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("3")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin13"
                                    >
                                      3
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 4 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin14"
                                      value="4"
                                      checked={middleWinSelectedCheckbox === "4"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("4")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin14"
                                    >
                                      4
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 5 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin15"
                                      value="5"
                                      checked={middleWinSelectedCheckbox === "5"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("5")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin15"
                                    >
                                      5
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 6 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin16"
                                      value="6"
                                      checked={middleWinSelectedCheckbox === "6"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("6")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin16"
                                    >
                                      6
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 7 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin17"
                                      value="7"
                                      checked={middleWinSelectedCheckbox === "7"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("7")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin17"
                                    >
                                      7
                                    </label>
                                  </div>
                                </li>

                                {/* Checkbox for value 8 */}
                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin18"
                                      value="8"
                                      checked={middleWinSelectedCheckbox === "8"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("8")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin18"
                                    >
                                      8
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="middleWin"
                                      id="middleWin19"
                                      value="9"
                                      checked={middleWinSelectedCheckbox === "9"}
                                      onChange={() =>
                                        handleMiddleWinCheckboxChange("9")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="middleWin19"
                                    >
                                      9
                                    </label>
                                  </div>
                                </li>
                              </div>


                              <div className="col-md-4 mt-1">

                                <b style={{ fontSize: '15px' }}>
                                  Triple Win
                                </b>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin20"
                                      value="0"
                                      checked={outerWinSelectedCheckbox === "0"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("0")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin20"
                                    >
                                      0
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin21"
                                      value="1"
                                      checked={outerWinSelectedCheckbox === "1"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("1")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin21"
                                    >
                                      1
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin22"
                                      value="2"
                                      checked={outerWinSelectedCheckbox === "2"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("2")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin22"
                                    >
                                      2
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin23"
                                      value="3"
                                      checked={outerWinSelectedCheckbox === "3"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("3")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin23"
                                    >
                                      3
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin24"
                                      value="4"
                                      checked={outerWinSelectedCheckbox === "4"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("4")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin24"
                                    >
                                      4
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin25"
                                      value="5"
                                      checked={outerWinSelectedCheckbox === "5"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("5")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin25"
                                    >
                                      5
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin26"
                                      value="6"
                                      checked={outerWinSelectedCheckbox === "6"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("6")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin27"
                                    >
                                      6
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin27"
                                      value="7"
                                      checked={outerWinSelectedCheckbox === "7"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("7")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin27"
                                    >
                                      7
                                    </label>
                                  </div>
                                </li>


                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin28"
                                      value="8"
                                      checked={outerWinSelectedCheckbox === "8"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("8")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin28"
                                    >
                                      8
                                    </label>
                                  </div>
                                </li>

                                <li className="list-group-items">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin29"
                                      value="9"
                                      checked={outerWinSelectedCheckbox === "9"}
                                      onChange={() =>
                                        handleOuterWinCheckboxChange("9")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="outerWin29"
                                    >
                                      9
                                    </label>
                                  </div>
                                </li>

                              </div>
                            </div>
                          </div>
                        </ul>


                        {/* Mobile View */}

                        <div className="mobileView">

                          <div className="row">
                            <div className="col-md-4 mt-1">


                              <select className="" value={innerWinDropDownMenu}
                                onChange={handleInnerWinCheckboxChange1}
                                style={{ width: '150px', marginTop: '20px' }}>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin0"
                                  value=""
                                >
                                  Select Single Win
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin0"
                                  value="0"
                                >
                                  0
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin1"
                                  value="1"
                                >
                                  1
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin2"
                                  value="2"
                                >
                                  2
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin3"
                                  value="3"
                                >
                                  3
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin4"
                                  value="4"
                                >
                                  4
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin5"
                                  value="5"
                                >
                                  5
                                </option>

                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin6"
                                  value="6"
                                >
                                  6
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin7"
                                  value="7"
                                >
                                  7
                                </option>

                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin8"
                                  value="8"
                                >
                                  8
                                </option>


                                <option
                                  className="form-check-input"
                                  type="checkbox"
                                  name="innerWin"
                                  id="innerWin9"
                                  value="9"
                                >
                                  9
                                </option>
                              </select>


                              {/* Double Win */}

                              <div className="col-md-4 mt-1">
                                {/* Checkbox for value 0 */}

                                <select onChange=
                                  {handleMiddleWinDropDownMenu} value={middleWinDropDownMenu}
                                  style={{ width: '150px', marginTop: '20px' }} className = "dropdown2">

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin10"
                                    value=""
                                  >
                                    Select Double Win
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin10"
                                    value="0"
                                  >
                                    0
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin11"
                                    value="1"
                                  >
                                    1
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin12"
                                    value="2"
                                  >
                                    2
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin13"
                                    value="3"
                                  >
                                    3
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin14"
                                    value="4"
                                  >
                                    4
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin15"
                                    value="5"
                                  >
                                    5
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin16"
                                    value="6"
                                  >
                                    6
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin17"
                                    value="7"
                                  >
                                    7
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin18"
                                    value="8"
                                  >
                                    8
                                  </option>

                                  <option
                                    className="form-check-input"
                                    type="checkbox"
                                    name="middleWin"
                                    id="middleWin19"
                                    value="9"
                                  >
                                    9
                                  </option>
                                </select>

                                {/* Triple Win */}

                                <div className="col-md-4 mt-1">


                                  <select style={{ width: '150px', marginTop: '20px', marginLeft: '-30px' }}
                                    onChange={handleOuterWinDropDownMenu}
                                    value={outerWinDropDownMenu} >


                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin20"
                                      value=""
                                    >
                                      Select Triple Win
                                    </option>
                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin20"
                                      value="0"
                                    >
                                      0
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin21"
                                      value="1"
                                    >
                                      1
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin22"
                                      value="2"
                                    >
                                      2
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin23"
                                      value="3">
                                      3
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin24"
                                      value="4">
                                      4
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin25"
                                      value="5">
                                      5
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin26"
                                      value="6">
                                      6
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin27"
                                      value="7">
                                      7
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin28"
                                      value="8">
                                      8
                                    </option>

                                    <option
                                      className="form-check-input"
                                      type="checkbox"
                                      name="outerWin"
                                      id="outerWin29"
                                      value="9">
                                      9
                                    </option>
                                  </select>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                  {/* First End */}

                  <div className="col-md-9">
                    <h4>
                      Lowest:{minname}-<h5>{min}</h5>

                    </h4>
                    <h4>
                      Highest:{maxname}-<h5>{max}</h5>
                    </h4>

                    <MaterialTable
                      title="Current Betting User List"
                      data={data}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default ShowCurrentBet;
