// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custome-ul-class {
    list-style-type: none;
}

.two-column-list {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .win-times {
        font-size: 12px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/style/GameBetCheckBox.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".custome-ul-class {\n    list-style-type: none;\n}\n\n.two-column-list {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n@media (max-width: 767px) {\n    .win-times {\n        font-size: 12px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
