import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../style/Login.css";
import axios from "axios";
import { apiBaseURL } from "../config";
import PropTypes from "prop-types";

const Login = ({ setToken }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [error, setErrorMsg] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    const savedPassword = localStorage.getItem("rememberedPassword");
    if (savedEmail && savedPassword) {
      setValues((prev) => ({ ...prev, email: savedEmail, password: savedPassword, rememberMe: true }));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, rememberMe } = values;
    const user = { email, password };

    try {
      const response = await axios.post(`${apiBaseURL}/auth/login`, user);
      if (response.data.status === 200) {
        setToken(response.data.token);
        sessionStorage.setItem("loginEmail", email);
        setErrorMsg(response.data.message);
        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
          localStorage.setItem("rememberedPassword", password);
        } else {
          localStorage.removeItem("rememberedEmail");
          localStorage.removeItem("rememberedPassword");
        }
      } else {
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      history.push("/login");
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setValues({ ...values, rememberMe: e.target.checked });
  };

  return (
    <div>
      <div className="boxStyle d-flex justify-content-center">
        <img src="logo5.jpg" width="170" height="150" alt="Bigwinner" />
      </div>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Paper elevation={15} className="paperStyle">
            <Grid align="center">
              <h3>Sign In to start your session</h3>
            </Grid>
            <div className="signin1">
              <TextField
                className="my-4 d-flex justify-content-center"
                id="outlined-basic1"
                label="Email or Username"
                value={values.email}
                onChange={handleChange("email")}
                name="email"
                type="text"
                placeholder="Email or UserId"
                variant="standard"
              />
            </div>
            <div className="signin1">
              <TextField
                className="my-1 d-flex justify-content-center"
                id="outlined-basic"
                label="Password"
                name="password"
                value={values.password}
                onChange={handleChange("password")}
                type="password"
                variant="standard"
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.rememberMe}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Remember Me"
            />
            <Button
              type="submit"
              className="my-0"
              variant="contained"
              color="primary"
              fullWidth
            >
              Sign In
            </Button>
            <div className="my-5">
              {error && (
                <div className="alert alert-warning" role="alert">
                  {error}
                </div>
              )}
            </div>
          </Paper>
        </Grid>
      </form>
    </div>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
