import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function AppHeader() {
  const [onlinePlayer, setOnlinePlayer] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const modalStyle = {
    display: 'block',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };


  const logoutUser = async () => {
    sessionStorage.removeItem("token");
    window.location.href = "/";
  };

  const getOnlinePlayer = async () => {
    try {
      let response = await axios.post(
        `https://royalluck.club:5000/player/onlinePlayer`,
        { roleId: 1 }
      );

      let data = response.data.data;

      console.log("data of online player", data);

      if (data && Array.isArray(data)) {
        setOnlinePlayer(data); // Update state with online player data
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  useEffect(() => {
    getOnlinePlayer();
    const interval = setInterval(getOnlinePlayer, 60000);

    return () => clearInterval(interval);
  }, []);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState); 
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-dark">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars"></i>
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="/" className="nav-link">
            Home
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          {/* <a href="#" className="nav-link">
            Contact
          </a> */}
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="navbar-search"
            href="#"
            role="button"
          >
            <i className="fas fa-search"></i>
          </a>
          <div className="navbar-search-block">
            <form className="form-inline">
              <div className="input-group input-group-sm">
                <input
                  className="form-control form-control-navbar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                {/* <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                  <button
                    className="btn btn-navbar"
                    type="button"
                    data-widget="navbar-search"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div> */}
              </div>
            </form>
          </div>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-comments"></i>
            <span className="badge badge-danger navbar-badge"></span>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" className="dropdown-item">
              <div className="media">
                <img
                  src="dist/img/user1-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 mr-3 img-circle"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Brad Diesel
                    <span className="float-right text-sm text-danger">
                      <i className="fas fa-star"></i>
                    </span>
                  </h3>
                  <p className="text-sm">Call me whenever you can...</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
              <div className="media">
                <img
                  src="dist/img/user8-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 img-circle mr-3"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    John Pierce
                    <span className="float-right text-sm text-muted">
                      <i className="fas fa-star"></i>
                    </span>
                  </h3>
                  <p className="text-sm">I got your message bro</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
              <div className="media">
                <img
                  src="dist/img/user3-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 img-circle mr-3"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Nora Silvester
                    <span className="float-right text-sm text-warning">
                      <i className="fas fa-star"></i>
                    </span>
                  </h3>
                  <p className="text-sm">The subject goes here</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item dropdown-footer">
              See All Messages
            </a>
          </div>
        </li>
               <li className="nav-item dropdown" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
  <span
    className="badge badge-warning navbar-badge"
    onClick={toggleModal}
    style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    {onlinePlayer.length > 0 ? onlinePlayer[0]?.onlineCount || "" : ""}
  </span>

  {/* Modal for displaying online player emails */}
  {showModal && (
    <div className="modal" style={modalStyle}>
      <div className="modal-content" style={{ textAlign: "center" }}>
        {/* Close button for the modal */}
        <span
          className="close"
          onClick={toggleModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "30px",
            color: "#aaa",
            cursor: "pointer",
          }}
        >
          &times;
        </span>
        <h4>Online Players</h4>
        {/* Check if there are any online players to display */}
        {onlinePlayer.length > 0 ? (
          <ul style={{ listStyle: "none", padding: 0 }}>
            {onlinePlayer.map((player, index) => (
              <li key={index}>{player.email}</li>
            ))}
          </ul>
        ) : (
          <p>No online players available.</p>
        )}
      </div>
    </div>
  )}
</li>

          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header"></span>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
              <i className="fas fa-envelope mr-2"></i> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
              <i className="fas fa-users mr-2"></i> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
              <i className="fas fa-file mr-2"></i> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item dropdown-footer"></a>
          </div>
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <i className="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            onClick={() => logoutUser()}
            data-toggle="dropdown"
            to="/"
          >
            <span>
              {" "}
              <i className="fas fa-sign-out-alt"></i> Logout
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
export default AppHeader;
