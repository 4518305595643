// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@media (max-width: 767px){
    .sec1{
        width: 100vw;
        margin-left: -10px;
    }
}



`, "",{"version":3,"sources":["webpack://./src/style/AppContent.css"],"names":[],"mappings":";;AAEA;IACI;QACI,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":["\n\n@media (max-width: 767px){\n    .sec1{\n        width: 100vw;\n        margin-left: -10px;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
