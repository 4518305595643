// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custome-ul-class {
    list-style-type: none;
}

.two-column-list {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .win-times {
        font-size: 12px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/style/GameBetCheckBox.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".custome-ul-class {\r\n    list-style-type: none;\r\n}\r\n\r\n.two-column-list {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .win-times {\r\n        font-size: 12px;\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
