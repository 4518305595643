import React, { useState, useEffect } from "react";
import axios from "axios";
import MaterialTable from "material-table";

const SupermasterMasterCommission = () => {
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState("");

  const apiBaseURL = "https://royalluck.club:5000";
  const getNextMonday = () => {
    const today = new Date();
    const day = today.getDay();
    const diff = 8 - day; 
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + diff);
    return nextMonday.toLocaleDateString("en-US");
  };

  // Fetch Commission Data
  const handleFetchCommissionData = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getSupermasterAndMasterCommission`);

      if (response.data.status === 200 && response.data.data.length > 0) {
        setTableData(response.data.data);
        setError("");
      } else {
        setError("No Record Found");
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching commission data:", error);
      setError("Error fetching data");
    }
  };

  // Define table columns
  const columns = [
    {
      title: "Serial No",
      render: (rowData) => rowData.tableData.id + 1,
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Type",
      field: "type",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Name",
      field: "name",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Point Played",
      field: "points_played",
      headerStyle: { fontSize: "15px" },
    },
    {
      title: "Commission",  
      field: "commission",
      headerStyle: { fontSize: "15px" },
    },
  ];

  useEffect(() => {
    handleFetchCommissionData();
  }, []);

  return (
    <div className="card card-outline card-info p-3">
      <h3>Upcomming Supermaster & Master Commission Details</h3>
      <div style={{ textAlign: "right", paddingRight: "20px" }}>
        <h3>Allocation Date: {getNextMonday()}</h3>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      <MaterialTable
        title="Commission Data"
        data={tableData}
        columns={columns}
        options={{ actionsColumnIndex: -1, exportButton: true }}
      />
    </div>
  );
};

export default SupermasterMasterCommission;
